import { Box, Grid } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PersonIcon from '@mui/icons-material/Person';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import BadgeIcon from '@mui/icons-material/Badge';
import Chip from './chip';
import CopyrightIcon from '@mui/icons-material/Copyright';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';

const Reports = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const navigate = useNavigate();
  return (
    <div>
      <Grid
        container
        sx={{
          mt: 1,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'start',
          width: 'auto',
          xs: { width: '100%', mx: 0,px: 0, },
          sm: { width: '100%', mx: 0, px: 0 },
          // md: { marginRight: '10rem', marginLeft: '10rem' }
        }}
        style={{
          marginRight: !isMobile ? '3rem':'2rem' ,
          marginLeft: !isMobile ? '3rem' :'2rem',
        }}
        spacing={2}
      >
        <Grid item xs={6} md={2}>
          <Chip icon={ReceiptIcon} text={'Sales Status'} path ={'/salesTaxReport'} />
        </Grid>
        <Grid item xs={5} md={2}>
          <Chip icon={RequestQuoteIcon} text={'Payroll status'}  path ={'/payrollStatusReport'}/>
        </Grid>
        {/* <Grid item xs={6} md={2}>
          <Chip icon={PersonIcon} text={'Client Details'} />
        </Grid> */}
        <Grid item xs={6} md={2}>
          <Chip icon={PriceCheckIcon} text={'Payroll Run Day'} path={'/payrollRunDayReport'}/>
        </Grid>
        <Grid item xs={6} md={2}>
          <Chip icon={LocalAtmIcon} text={'Payroll Summary'} path ={'/payrollReport'}/>
        </Grid>
        <Grid item xs={6} md={2}>
          <Chip icon={CreditCardIcon} text={'Sales Summary'} path ={'/salesReport'} />
        </Grid>
        <Grid item xs={6} md={2}>
          <Chip icon={BadgeIcon} text={'Employee'}  path ={'/employeeReport'}/>
        </Grid>
        <Grid item xs={6} md={2}>
          <Chip icon={CopyrightIcon} text={'Ownership'} path ={'/ownershipReport'} />
        </Grid>
        <Grid item xs={5} md={2}>
          <Chip icon={PersonIcon} text={'Clients'}  path ={'/clientsReport'}/>
        </Grid>
      </Grid>
    </div>
  );
};

export default Reports;
