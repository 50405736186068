import React, { useEffect, useState } from 'react';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { getSaleTaxReport } from 'src/services/salesService';
import {
  Autocomplete,
  ToggleButton,
  ToggleButtonGroup,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { BarLoader } from 'react-spinners';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import moment from 'moment';
import { getSalesTaxActiveClients } from 'src/services/clientService';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SearchIcon from '@mui/icons-material/Search';
import { formatClientId } from 'src/utils/helperFunctions';
import { NotificationManager } from 'react-notifications';
import { formatNumberWithCommas } from '../SalesForm/salesDoubleInput';

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'month', label: 'Month' },
  { id: 'gasolineGallons', label: 'Gasoline Gallons', minWidth: 100 },
  {
    id: 'gasolineAmount',
    label: 'Gasoline Amount',
    minWidth: 100
  },
  {
    id: 'carWash',
    label: 'Car Wash',
    minWidth: 100
  },
  {
    id: 'newsPaper',
    label: 'NewsPaper',
    minWidth: 100
  },
  {
    id: 'ebt',
    label: 'EBT',
    minWidth: 100
  },
  {
    id: 'exemptFood',
    label: 'Exempt Food',
    minWidth: 100
  },
  {
    id: 'saleTaxHoliday',
    label: 'Sale Tax Holiday',
    minWidth: 100
  },
  {
    id: 'otherExempt',
    label: 'Other Exempt',
    minWidth: 100
  },
  {
    id: 'totalExempt',
    label: 'Total Exempt',
    minWidth: 100
  },
  {
    id: 'highTaxGrocery',
    label: 'High Tax Grocery',
    minWidth: 100
  },
  {
    id: 'beer',
    label: 'Beer',
    minWidth: 100
  },
  {
    id: 'cigarettes',
    label: 'Cigarettes',
    minWidth: 100
  },
  {
    id: 'hotFoodDeli',
    label: 'Hot Food (Deli',
    minWidth: 100
  },
  {
    id: 'fountainDrink',
    label: 'Fountain Drink',
    minWidth: 100
  },
  {
    id: 'hotCoffee',
    label: 'Hot Coffee',
    minWidth: 100
  },
  {
    id: 'tobacco',
    label: 'Tobacco',
    minWidth: 100
  },
  {
    id: 'novelties',
    label: 'Novelties',
    minWidth: 100
  },
  {
    id: 'phoneCards',
    label: 'Phone Cards',
    minWidth: 100
  },
  {
    id: 'cbd',
    label: 'CBD (Delta -8 & -10)',
    minWidth: 100
  },
  {
    id: 'candies',
    label: 'Candies',
    minWidth: 100
  },
  {
    id: 'wine',
    label: 'Wine',
    minWidth: 100
  },
  {
    id: 'otherHigh',
    label: 'Other High',
    minWidth: 100
  },
  {
    id: 'totalHighTaxSales',
    label: 'Total High Tax Sales',
    minWidth: 100
  },
  {
    id: 'lowTaxGrocery',
    label: 'Low Tax Grocery',
    minWidth: 100
  },
  {
    id: 'foodIngredients',
    label: 'Food Ingredients',
    minWidth: 100
  },
  {
    id: 'iceCream',
    label: 'Ice Cream',
    minWidth: 100
  },
  {
    id: 'bottledWater',
    label: 'Bottled Water',
    minWidth: 100
  },
  {
    id: 'fruitsVegetables',
    label: 'Fruits/Vegetables',
    minWidth: 100
  },
  {
    id: 'softDrinks',
    label: 'Soft Drinks',
    minWidth: 100
  },
  {
    id: 'coffee',
    label: 'Coffee',
    minWidth: 100
  },
  {
    id: 'chips',
    label: 'Chips',
    minWidth: 100
  },
  {
    id: 'dairy',
    label: 'Dairy',
    minWidth: 100
  },
  {
    id: 'rawMeat',
    label: 'Raw Meat',
    minWidth: 100
  },
  {
    id: 'otherLow',
    label: 'Other Low',
    minWidth: 100
  },
  {
    id: 'totalLowTaxSales',
    label: 'Total Low Tax Sales',
    minWidth: 100
  },
  {
    id: 'totalSalesTax',
    label: 'Total Sales Tax',
    minWidth: 100
  },
  {
    id: 'salesTaxPayable',
    label: 'Sales Tax Payable',
    minWidth: 100
  },
  {
    id: 'total',
    label: 'Total',
    minWidth: 100
  }
];

const summaryColumns: Column[] = [
  { id: 'month', label: 'Month' },
  { id: 'gasolineGallons', label: 'Gasoline Gallons', minWidth: 100 },
  {
    id: 'gasolineAmount',
    label: 'Gasoline Amount',
    minWidth: 100
  },
  {
    id: 'totalExempt',
    label: 'Total Exempt',
    minWidth: 100
  },
  {
    id: 'totalHighTaxSales',
    label: 'Total High Tax Sales',
    minWidth: 100
  },
  {
    id: 'totalLowTaxSales',
    label: 'Total Low Tax Sales',
    minWidth: 100
  },
  {
    id: 'totalSalesTax',
    label: 'Total Sales Tax',
    minWidth: 100
  },
  {
    id: 'salesTaxPayable',
    label: 'Sales Tax Payable',
    minWidth: 100
  },
  {
    id: 'total',
    label: 'Total',
    minWidth: 100
  }
];

const monthsArray = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];
const SalesReport = () => {
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedYear, setSelectedYear] = useState(moment().format('YYYY'));
  const [selectedStartMonth, setSelectedStartMonth] = useState(null);
  const [selectedEndMonth, setSelectedEndMonth] = useState(null);
  const [selectedClient, setSelectedClient]: any = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [rows, setRows] = useState([]);
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('asc');
  const [reportType, setReportType] = useState('Detailed');

  const styles = {
    tableCell: { border: '1px solid #D8D8D8', padding: 1 },
    tableCellTotal: {
      border: '1px solid #D8D8D8',
      padding: 1,
      fontWeight: 'bold'
    },
    excelSheetCell: { border: '1px solid #f1f1f1', padding: 1 }
  };

  const getClients = async () => {
    try {
      const data = await getSalesTaxActiveClients();
      console.log('data::', data);
      setClients(data.data);
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };

  const handleClientChange = async (value) => {
    setSelectedClient(value);
  };
  const handleExportDetailedReport = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Styled Sheet');

    worksheet.addRow([]); // Add an empty row
    worksheet.addRow([
      `Legal Name: ${
        (selectedClient && selectedClient.legalBusinessName) || ''
      }`
    ]); // Row for Legal Business Name
    worksheet.addRow([
      `DBA: ${(selectedClient && selectedClient.doingBusinessAs) || ''}`
    ]); // Row for DBA
    worksheet.addRow([
      `Location:  ${(selectedClient && selectedClient.streetAddress1) || ''}, ${
        (selectedClient && selectedClient.city) || ''
      }, ${(selectedClient && selectedClient.state) || ''}, ${
        (selectedClient && selectedClient.zipCode) || ''
      } `
    ]); // Row for Location
    worksheet.addRow([
      `Report Period:  ${moment(selectedStartMonth).format(
        'MMM-YY'
      )} to ${moment(selectedEndMonth).format('MMM-YY')}`
    ]); // Add an empty row
    worksheet.addRow([]); // Add an empty row
    worksheet.addRow([
      'Month',
      'Gasoline',
      '',
      'Tax Exempt Sales',
      '',
      '',
      '',
      '',
      '',
      '',
      'High Tax Sales',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      'Low Tax Sales',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      ''
    ]); // Row for Sale Category
    worksheet.addRow([
      '',
      'Gallons',
      'Amount',
      'Car Wash',
      'Newspapers',
      'EBT',
      'Exempt Food',
      'Sale Tax Holiday',
      'Other Exempt',
      'Total Exempt',
      'High Tax Grocery',
      'Beer',
      'Cigarettes',
      'Hot Food (Deli)',
      'Fountain Drink',
      'Hot Coffee',
      'Tobacco',
      'Novelties',
      'Phone Cards',
      'CBD (Delta -8 & -10)',
      'Candies',
      'Wine',
      'Other High',
      'Total High Tax Sales',
      'Low Tax Grocery',
      'Food Ingredients',
      'Ice Cream',
      'Bottled Water',
      'Fruits/Vegetables',
      'Soft Drinks',
      'Coffee',
      'Chips',
      'Dairy',
      'Raw Meat',
      'Other Low',
      'Total Low Tax Sales',
      'Total Sales Tax',
      'Sales Tax Payable',
      'Total'
    ]); // Row for Sale Name

    const legalName = worksheet.getCell('A2');
    const dba = worksheet.getCell('A3');
    const location = worksheet.getCell('A4');
    const reportPeriod = worksheet.getCell('A5');

    legalName.font = { bold: true, color: { argb: 'ff1c4e80' } };
    dba.font = { bold: true, color: { argb: 'ff1c4e80' } };
    location.font = { bold: true, color: { argb: 'ff1c4e80' } };
    reportPeriod.font = { bold: true, color: { argb: 'ff1c4e80' } };

    // Add color to complere row 7
    worksheet.getRow(7).eachCell((cell, colNumber) => {
      cell.font = { bold: true, color: { argb: 'ff1c4e80' } };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'ffc6eob4' }
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
      cell.alignment = { horizontal: 'center', vertical: 'middle' };
    });
    worksheet.getRow(8).eachCell((cell, colNumber) => {
      cell.font = { bold: true, color: { argb: 'ff1c4e80' } };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'ffc6eob4' }
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
      cell.alignment = { horizontal: 'center', vertical: 'middle' };
    });
    worksheet.columns = [
      { width: 10 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 }
    ];

    worksheet.mergeCells('A2:E2'); // merging for Legal Business Name
    worksheet.mergeCells('A3:E3'); // merging for DBA
    worksheet.mergeCells('A4:E4'); // merging for Location
    worksheet.mergeCells('A5:E5'); // merging for Report Period
    worksheet.mergeCells('A7:A8'); // merging for Month
    worksheet.mergeCells('B7:C7'); // merging for Gasoline
    worksheet.mergeCells('D7:J7'); // merging for Tax Exempt Sales
    worksheet.mergeCells('K7:X7'); // merging for High Tax Sales
    worksheet.mergeCells('Y7:AJ7'); // merging for High Tax Sales
    worksheet.mergeCells('AK7:AM7'); // merging for Totals

    // Add some data rows with different styles
    const data = [];
    rows.map((item) => {
      data.push([
        item.month.slice(0, 3) + '-' + item.year,
        +item.gasolineGallon,
        +item.gasolineAmount,
        +item.carWash,
        +item.newsPaper,
        +item.ebt,
        +item.exemptFood,
        +item.saleTaxHoliday,
        +item.otherExempt,
        +item.totalExempt,
        +item.highTaxGrocery,
        +item.beer,
        +item.cigarettes,
        +item.hotFoodDeli,
        +item.fountainDrink,
        +item.hotCoffee,
        +item.tobacco,
        +item.novelties,
        +item.phoneCards,
        +item.cbdDelta,
        +item.candies,
        +item.wine,
        +item.otherHigh,
        +item.totalHighTax,
        +item.lowTaxGrocery,
        +item.foodIngredients,
        +item.iceCream,
        +item.bottledWater,
        +item.fruitsVegetables,
        +item.softDrinks,
        +item.coffee,
        +item.chips,
        +item.dairy,
        +item.rawMeet,
        +item.otherLow,
        +item.totalLowTax,
        +item.totalSalesTax,
        +item.netSalesTax,
        +calculateRowTotal(item)
      ]);
    });

    data.push([
      'Total',
      +rows.reduce((sum, row) => sum + +row.gasolineGallon, 0).toFixed(2),
      +rows.reduce((sum, row) => sum + +row.gasolineAmount, 0).toFixed(2),
      +rows.reduce((sum, row) => sum + +row.carWash, 0).toFixed(2),
      +rows.reduce((sum, row) => sum + +row.newsPaper, 0).toFixed(2),
      +rows.reduce((sum, row) => sum + +row.ebt, 0).toFixed(2),
      +rows.reduce((sum, row) => sum + +row.exemptFood, 0).toFixed(2),
      +rows.reduce((sum, row) => sum + +row.saleTaxHoliday, 0).toFixed(2),
      +rows.reduce((sum, row) => sum + +row.otherExempt, 0).toFixed(2),
      +rows.reduce((sum, row) => sum + +row.totalExempt, 0).toFixed(2),
      +rows.reduce((sum, row) => sum + +row.highTaxGrocery, 0),
      +rows.reduce((sum, row) => sum + +row.beer, 0).toFixed(2),
      +rows.reduce((sum, row) => sum + +row.cigarettes, 0).toFixed(2),
      +rows.reduce((sum, row) => sum + +row.hotFoodDeli, 0).toFixed(2),
      +rows.reduce((sum, row) => sum + +row.fountainDrink, 0).toFixed(2),
      +rows.reduce((sum, row) => sum + +row.hotCoffee, 0).toFixed(2),
      +rows.reduce((sum, row) => sum + +row.tobacco, 0).toFixed(2),
      +rows.reduce((sum, row) => sum + +row.novelties, 0).toFixed(2),
      +rows.reduce((sum, row) => sum + +row.phoneCards, 0).toFixed(2),
      +rows.reduce((sum, row) => sum + +row.cbdDelta, 0).toFixed(2),
      +rows.reduce((sum, row) => sum + +row.candies, 0).toFixed(2),
      +rows.reduce((sum, row) => sum + +row.wine, 0).toFixed(2),
      +rows.reduce((sum, row) => sum + +row.otherHigh, 0).toFixed(2),
      +rows.reduce((sum, row) => sum + +row.totalHighTax, 0).toFixed(2),
      +rows.reduce((sum, row) => sum + +row.lowTaxGrocery, 0).toFixed(2),
      +rows.reduce((sum, row) => sum + +row.foodIngredients, 0).toFixed(2),
      +rows.reduce((sum, row) => sum + +row.iceCream, 0).toFixed(2),
      +rows.reduce((sum, row) => sum + +row.bottledWater, 0).toFixed(2),
      +rows.reduce((sum, row) => sum + +row.fruitsVegetables, 0).toFixed(2),
      +rows.reduce((sum, row) => sum + +row.softDrinks, 0).toFixed(2),
      +rows.reduce((sum, row) => sum + +row.coffee, 0).toFixed(2),
      +rows.reduce((sum, row) => sum + +row.chips, 0).toFixed(2),
      +rows.reduce((sum, row) => sum + +row.dairy, 0).toFixed(2),
      +rows.reduce((sum, row) => sum + +row.rawMeet, 0).toFixed(2),
      +rows.reduce((sum, row) => sum + +row.otherLow, 0).toFixed(2),
      +rows.reduce((sum, row) => sum + +row.totalLowTax, 0).toFixed(2),
      +rows.reduce((sum, row) => sum + +row.totalSalesTax, 0).toFixed(2),
      +rows.reduce((sum, row) => sum + +row.netSalesTax, 0).toFixed(2),
      +rows.reduce((sum, row) => sum + +calculateRowTotal(row), 0).toFixed(2)
    ]);

    data.forEach((item) => {
      const dataRow = worksheet.addRow(item);
      dataRow.eachCell((cell) => {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
        cell.alignment = { horizontal: 'center', vertical: 'middle' };
      });
    });

    // Generate buffer and save the file
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(
      new Blob([buffer]),
      `${formatClientId(selectedClient?.id || 0)}-SalesReport-${moment(
        selectedStartMonth
      ).format('MMM-YY')}-${moment(selectedEndMonth).format('MMM-YY')}.xlsx`
    );
  };

  const handleExportSummaryReport = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Styled Sheet');

    worksheet.addRow([]); // Add an empty row
    worksheet.addRow([
      `Legal Name: ${
        (selectedClient && selectedClient.legalBusinessName) || ''
      }`
    ]); // Row for Legal Business Name
    worksheet.addRow([
      `DBA: ${(selectedClient && selectedClient.doingBusinessAs) || ''}`
    ]); // Row for DBA
    worksheet.addRow([
      `Location:  ${(selectedClient && selectedClient.streetAddress1) || ''}, ${
        (selectedClient && selectedClient.city) || ''
      }, ${(selectedClient && selectedClient.state) || ''}, ${
        (selectedClient && selectedClient.zipCode) || ''
      } `
    ]); // Row for Location
    worksheet.addRow([
      `Report Period:  ${moment(selectedStartMonth).format(
        'MMM-YY'
      )} to ${moment(selectedEndMonth).format('MMM-YY')}`
    ]); // Add an empty row
    worksheet.addRow([]); // Add an empty row
    worksheet.addRow([
      'Months',
      'Gasoline Gallons',
      'Gasoline Amount',
      'Total Exempt',
      'Total High Tax Sales',
      'Total Low Tax Sales',
      'Total Sales Tax',
      'Sales Tax Payable',
      'Total'
    ]);
  ;

    const legalName = worksheet.getCell('A2');
    const dba = worksheet.getCell('A3');
    const location = worksheet.getCell('A4');
    const reportPeriod = worksheet.getCell('A5');

    legalName.font = { bold: true, color: { argb: 'ff1c4e80' } };
    dba.font = { bold: true, color: { argb: 'ff1c4e80' } };
    location.font = { bold: true, color: { argb: 'ff1c4e80' } };
    reportPeriod.font = { bold: true, color: { argb: 'ff1c4e80' } };

    // Add color to complere row 7
    worksheet.getRow(7).eachCell((cell, colNumber) => {
      cell.font = { bold: true, color: { argb: 'ff1c4e80' } };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'ffc6eob4' }
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
      cell.alignment = { horizontal: 'center', vertical: 'middle' };
    });
    worksheet.columns = [
      { width: 10 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 }
    ];

    worksheet.mergeCells('A2:E2'); // merging for Legal Business Name
    worksheet.mergeCells('A3:E3'); // merging for DBA
    worksheet.mergeCells('A4:E4'); // merging for Location
    worksheet.mergeCells('A5:E5'); // merging for Report Period

    // Add some data rows with different styles
    const data = [];
    rows.map((item) => {
      data.push([
        item.month.slice(0, 3) + '-' + item.year,
        +item.gasolineGallon,
        +item.gasolineAmount,
        +item.totalExempt,
        +item.totalHighTax,
        +item.totalLowTax,
        +item.totalSalesTax,
        +item.netSalesTax,
        +calculateRowTotal(item)
      ]);
    });

    data.push([
      'Total',
      +rows.reduce((sum, row) => sum + +row.gasolineGallon, 0).toFixed(2),
      +rows.reduce((sum, row) => sum + +row.gasolineAmount, 0).toFixed(2),
      +rows.reduce((sum, row) => sum + +row.totalExempt, 0).toFixed(2),
      +rows.reduce((sum, row) => sum + +row.totalHighTax, 0).toFixed(2),
      +rows.reduce((sum, row) => sum + +row.totalLowTax, 0).toFixed(2),
      +rows.reduce((sum, row) => sum + +row.totalSalesTax, 0).toFixed(2),
      +rows.reduce((sum, row) => sum + +row.netSalesTax, 0).toFixed(2),
      +rows.reduce((sum, row) => sum + +calculateRowTotal(row), 0).toFixed(2)
    ]);

    data.forEach((item) => {
      const dataRow = worksheet.addRow(item);
      dataRow.eachCell((cell) => {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
        cell.alignment = { horizontal: 'center', vertical: 'middle' };
      });
    });
    // Generate buffer and save the file
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(
      new Blob([buffer]),
      `${formatClientId(selectedClient?.id || 0)}-SalesReport-Summary -${moment(
        selectedStartMonth
      ).format('MMM-YY')}-${moment(selectedEndMonth).format('MMM-YY')}.xlsx`
    );
  };

  const getSaleReport = async () => {
    try {
      setLoading(true);
      const response: any = await getSaleTaxReport({
        clientId: selectedClient?.id || 0,
        monthStart: moment(selectedStartMonth).format('YYYY-MM') || '0',
        monthEnd: moment(selectedEndMonth).format('YYYY-MM') || '0'
      });
      if (response) {
        setRows(response && response.data);
      } else {
        setRows([]);
        console.log('No data found');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }

    //   if (response) {}
  };

  const handleClear = () => {
    setSelectedClient(null);
    setSelectedYear(null);
    setSelectedStartMonth(null);
    setSelectedEndMonth(null);
  };

  const handleSearch = () => {
    if (!selectedClient || !selectedStartMonth || !selectedEndMonth) {
      NotificationManager.error(
        `Please select all the required fields`,
        'Error',
        3000
      );
      return;
    }
    getSaleReport();
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleReportTypeChange = (event) => {
    setReportType(event.target.value);
    console.log(reportType);
  };

  // useEffect(() => {
  //   getSaleReport()}, []);

  useEffect(() => {
    const fetchInitialData = async () => {
      await getClients();
    };
    fetchInitialData();
  }, []);

  return (
    <>
      <Box my={2} mx={5}>
        {loading && (
          <div className="loader-wrapper" style={{ textAlign: 'center' }}>
            <BarLoader color={'#1976D2'} loading={loading} width={'94vw'} />
          </div>
        )}
        <Typography
          sx={{
            textAlign: 'center',
            fontWeight: 'bold',
            color: '#1976D2',
            fontSize: '1.5em',
            my: 2
          }}
        >
          Sales Summary
        </Typography>
        <Grid container spacing={2} mt={0}>
          <Grid item xs={12} md={3.5}>
            <InputLabel htmlFor="client">Client</InputLabel>
            <Autocomplete
              id="client"
              options={clients}
              getOptionLabel={(client) => client.legalBusinessName || ''}
              value={selectedClient}
              onChange={(_, newValue) => handleClientChange(newValue)}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" size="small" />
              )}
            />
          </Grid>
          <Grid item xs={6} md={1.5} mt={0}>
            <InputLabel htmlFor="startMonth">Start Month</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Box sx={{ width: '100%' }}>
                <DatePicker
                  views={['year', 'month']}
                  openTo="year"
                  value={selectedStartMonth}
                  inputFormat="MMM yyyy"
                  onChange={setSelectedStartMonth}
                  renderInput={(props) => (
                    <TextField {...props} style={{ width: '100%' }} />
                  )}
                  InputProps={{
                    size: 'small',
                    name: 'startMonth'
                  }}
                />
              </Box>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6} md={1.5} mt={0}>
            <InputLabel htmlFor="endMonth">End Month</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Box sx={{ width: '100%' }}>
                <DatePicker
                  views={['year', 'month']}
                  openTo="year"
                  value={selectedEndMonth}
                  inputFormat="MMM yyyy"
                  onChange={setSelectedEndMonth}
                  renderInput={(props) => (
                    <TextField {...props} style={{ width: '100%' }} />
                  )}
                  InputProps={{
                    size: 'small',
                    name: 'endMonth'
                  }}
                />
              </Box>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={3.5} sm={3} md={1} sx={{ mt: { md: 3 } }}>
            <Button
              variant="contained"
              color="warning"
              onClick={handleClear}
              sx={{ scale: { xs: '0.8', md: '1' } }}
            >
              Clear
            </Button>
          </Grid>
          <Grid item xs={4} sm={2} md={1} sx={{ mt: { md: 3 } }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSearch}
              sx={{ scale: { xs: '0.8', md: '1' } }}
            >
              Search
              <SearchIcon />
            </Button>
          </Grid>
          <Grid item xs={0.1} sm={0} md={2}></Grid>
          <Grid item xs={3.8} sm={2} md={1} sx={{ mt: { md: 3 } }}>
            <Button
              variant="contained"
              color="success"
              onClick={
                reportType === 'Summary'
                  ? handleExportSummaryReport
                  : handleExportDetailedReport
              }
              sx={{ scale: { xs: '0.8', md: '1' } }}
            >
              Export
              <FileDownloadIcon />
            </Button>
          </Grid>
        </Grid>
        {/* <Grid mx={3} my={2} width={'inherit'} container spacing={2}>
          <Grid item md={6}></Grid>

          <Grid item md={6}></Grid>
        </Grid> */}
      </Box>
      <Box
        mx={5}
        mt={1}
        style={{ width: '96vw', display: 'flex', justifyContent: 'start' }}
      >
        <Grid
          container
          style={{ display: 'flex', justifyContent: 'start' }}
          mt={0}
        >
          <Grid item xs={4} md={3}>
            <ToggleButtonGroup
              size="small"
              value={reportType}
              exclusive
              onChange={handleReportTypeChange}
              style={{ marginRight: '2vw' }}
            >
              <ToggleButton
                value="Summary"
                style={{
                  backgroundColor:
                    reportType === 'Summary' ? '#1976d2' : 'white',
                  color: reportType === 'Summary' ? 'white' : 'black',
                  border: '1px solid #ccc'
                }}
              >
                Summary
              </ToggleButton>
              <ToggleButton
                value="Detailed"
                style={{
                  backgroundColor:
                    reportType === 'Detailed' ? '#1976d2' : 'white',
                  color: reportType === 'Detailed' ? 'white' : 'black',
                  border: '1px solid #ccc'
                }}
              >
                Detailed
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </Grid>
      </Box>
      {reportType === 'Detailed' && (
        <Box sx={{ mx: 5, width: 'inherit', overflow: 'hidden' }}>
          <Paper sx={{ width: '100%', overflow: 'auto' }} elevation={3}>
            <TableContainer style={{ marginLeft: '0px' }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          fontWeight: '800',
                          color: '#1976D2',
                          backgroundColor: '#f5f5f5'
                        }}
                        sx={styles.tableCell}
                      >
                        <Typography>{column.label}</Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                        >
                          <TableCell sx={styles.tableCell}>
                            {row.month.substring(0, 3)}-{row.year}
                          </TableCell>
                          <TableCell sx={styles.tableCell}>
                            {row.gasolineGallon}
                          </TableCell>
                          <TableCell sx={styles.tableCell}>
                            {row.gasolineAmount}
                          </TableCell>
                          <TableCell sx={styles.tableCell}>
                            {row.carWash}
                          </TableCell>
                          <TableCell sx={styles.tableCell}>
                            {row.newsPaper}
                          </TableCell>
                          <TableCell sx={styles.tableCell}>{row.ebt}</TableCell>
                          <TableCell sx={styles.tableCell}>
                            {row.exemptFood}
                          </TableCell>
                          <TableCell sx={styles.tableCell}>
                            {row.saleTaxHoliday}
                          </TableCell>
                          <TableCell sx={styles.tableCell}>
                            {row.otherExempt}
                          </TableCell>
                          <TableCell sx={styles.tableCell}>
                            {row.totalExempt}
                          </TableCell>
                          <TableCell sx={styles.tableCell}>
                            {row.highTaxGrocery}
                          </TableCell>
                          <TableCell sx={styles.tableCell}>
                            {row.beer}
                          </TableCell>
                          <TableCell sx={styles.tableCell}>
                            {row.cigarettes}
                          </TableCell>
                          <TableCell sx={styles.tableCell}>
                            {row.hotFoodDeli}
                          </TableCell>
                          <TableCell sx={styles.tableCell}>
                            {row.fountainDrink}
                          </TableCell>
                          <TableCell sx={styles.tableCell}>
                            {row.hotCoffee}
                          </TableCell>
                          <TableCell sx={styles.tableCell}>
                            {row.tobacco}
                          </TableCell>
                          <TableCell sx={styles.tableCell}>
                            {row.novelties}
                          </TableCell>
                          <TableCell sx={styles.tableCell}>
                            {row.phoneCards}
                          </TableCell>
                          <TableCell sx={styles.tableCell}>
                            {row.cbdDelta}
                          </TableCell>
                          <TableCell sx={styles.tableCell}>
                            {row.candies}
                          </TableCell>
                          <TableCell sx={styles.tableCell}>
                            {row.wine}
                          </TableCell>
                          <TableCell sx={styles.tableCell}>
                            {row.otherHigh}
                          </TableCell>
                          <TableCell sx={styles.tableCell}>
                            {row.totalHighTax}
                          </TableCell>
                          <TableCell sx={styles.tableCell}>
                            {row.lowTaxGrocery}
                          </TableCell>
                          <TableCell sx={styles.tableCell}>
                            {row.foodIngredients}
                          </TableCell>
                          <TableCell sx={styles.tableCell}>
                            {row.iceCream}
                          </TableCell>
                          <TableCell sx={styles.tableCell}>
                            {row.bottledWater}
                          </TableCell>
                          <TableCell sx={styles.tableCell}>
                            {row.fruitsVegetables}
                          </TableCell>
                          <TableCell sx={styles.tableCell}>
                            {row.softDrinks}
                          </TableCell>
                          <TableCell sx={styles.tableCell}>
                            {row.coffee}
                          </TableCell>
                          <TableCell sx={styles.tableCell}>
                            {row.chips}
                          </TableCell>
                          <TableCell sx={styles.tableCell}>
                            {row.dairy}
                          </TableCell>
                          <TableCell sx={styles.tableCell}>
                            {row.rawMeet}
                          </TableCell>
                          <TableCell sx={styles.tableCell}>
                            {row.otherLow}
                          </TableCell>
                          <TableCell sx={styles.tableCell}>
                            {row.totalLowTax}
                          </TableCell>
                          <TableCell sx={styles.tableCell}>
                            {row.totalSalesTax}
                          </TableCell>
                          <TableCell sx={styles.tableCell}>
                            {row.netSalesTax}
                          </TableCell>
                          <TableCell sx={styles.tableCellTotal}>
                            {formatNumberWithCommas(calculateRowTotal(row))}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {rows.length > 0 && (
                    <TableRow>
                      <TableCell sx={styles.tableCellTotal}>Total</TableCell>
                      <TableCell sx={styles.tableCellTotal}>
                        {formatNumberWithCommas(
                          rows
                            .reduce((sum, row) => sum + +row.gasolineGallon, 0)
                            .toFixed(2)
                        )}
                      </TableCell>
                      <TableCell sx={styles.tableCellTotal}>
                        {formatNumberWithCommas(
                          rows
                            .reduce((sum, row) => sum + +row.gasolineAmount, 0)
                            .toFixed(2)
                        )}
                      </TableCell>
                      <TableCell sx={styles.tableCellTotal}>
                        {formatNumberWithCommas(
                          rows
                            .reduce((sum, row) => sum + +row.carWash, 0)
                            .toFixed(2)
                        )}
                      </TableCell>
                      <TableCell sx={styles.tableCellTotal}>
                        {formatNumberWithCommas(
                          rows
                            .reduce((sum, row) => sum + +row.newsPaper, 0)
                            .toFixed(2)
                        )}
                      </TableCell>
                      <TableCell sx={styles.tableCellTotal}>
                        {formatNumberWithCommas(
                          rows
                            .reduce((sum, row) => sum + +row.ebt, 0)
                            .toFixed(2)
                        )}
                      </TableCell>
                      <TableCell sx={styles.tableCellTotal}>
                        {formatNumberWithCommas(
                          rows
                            .reduce((sum, row) => sum + +row.exemptFood, 0)
                            .toFixed(2)
                        )}
                      </TableCell>
                      <TableCell sx={styles.tableCellTotal}>
                        {formatNumberWithCommas(
                          rows
                            .reduce((sum, row) => sum + +row.saleTaxHoliday, 0)
                            .toFixed(2)
                        )}
                      </TableCell>
                      <TableCell sx={styles.tableCellTotal}>
                        {formatNumberWithCommas(
                          rows
                            .reduce((sum, row) => sum + +row.otherExempt, 0)
                            .toFixed(2)
                        )}
                      </TableCell>
                      <TableCell sx={styles.tableCellTotal}>
                        {formatNumberWithCommas(
                          rows
                            .reduce((sum, row) => sum + +row.totalExempt, 0)
                            .toFixed(2)
                        )}
                      </TableCell>
                      <TableCell sx={styles.tableCellTotal}>
                        {formatNumberWithCommas(
                          rows
                            .reduce((sum, row) => sum + +row.highTaxGrocery, 0)
                            .toFixed(2)
                        )}
                      </TableCell>
                      <TableCell sx={styles.tableCellTotal}>
                        {formatNumberWithCommas(
                          rows
                            .reduce((sum, row) => sum + +row.beer, 0)
                            .toFixed(2)
                        )}
                      </TableCell>
                      <TableCell sx={styles.tableCellTotal}>
                        {formatNumberWithCommas(
                          rows
                            .reduce((sum, row) => sum + +row.cigarettes, 0)
                            .toFixed(2)
                        )}
                      </TableCell>
                      <TableCell sx={styles.tableCellTotal}>
                        {formatNumberWithCommas(
                          rows
                            .reduce((sum, row) => sum + +row.hotFoodDeli, 0)
                            .toFixed(2)
                        )}
                      </TableCell>
                      <TableCell sx={styles.tableCellTotal}>
                        {formatNumberWithCommas(
                          rows
                            .reduce((sum, row) => sum + +row.fountainDrink, 0)
                            .toFixed(2)
                        )}
                      </TableCell>
                      <TableCell sx={styles.tableCellTotal}>
                        {formatNumberWithCommas(
                          rows
                            .reduce((sum, row) => sum + +row.hotCoffee, 0)
                            .toFixed(2)
                        )}
                      </TableCell>
                      <TableCell sx={styles.tableCellTotal}>
                        {formatNumberWithCommas(
                          rows
                            .reduce((sum, row) => sum + +row.tobacco, 0)
                            .toFixed(2)
                        )}
                      </TableCell>
                      <TableCell sx={styles.tableCellTotal}>
                        {formatNumberWithCommas(
                          rows
                            .reduce((sum, row) => sum + +row.novelties, 0)
                            .toFixed(2)
                        )}
                      </TableCell>
                      <TableCell sx={styles.tableCellTotal}>
                        {formatNumberWithCommas(
                          rows
                            .reduce((sum, row) => sum + +row.phoneCards, 0)
                            .toFixed(2)
                        )}
                      </TableCell>
                      <TableCell sx={styles.tableCellTotal}>
                        {formatNumberWithCommas(
                          rows
                            .reduce((sum, row) => sum + +row.cbdDelta, 0)
                            .toFixed(2)
                        )}
                      </TableCell>
                      <TableCell sx={styles.tableCellTotal}>
                        {formatNumberWithCommas(
                          rows
                            .reduce((sum, row) => sum + +row.candies, 0)
                            .toFixed(2)
                        )}
                      </TableCell>
                      <TableCell sx={styles.tableCellTotal}>
                        {formatNumberWithCommas(
                          rows
                            .reduce((sum, row) => sum + +row.wine, 0)
                            .toFixed(2)
                        )}
                      </TableCell>
                      <TableCell sx={styles.tableCellTotal}>
                        {formatNumberWithCommas(
                          rows
                            .reduce((sum, row) => sum + +row.otherHigh, 0)
                            .toFixed(2)
                        )}
                      </TableCell>
                      <TableCell sx={styles.tableCellTotal}>
                        {formatNumberWithCommas(
                          rows
                            .reduce((sum, row) => sum + +row.totalHighTax, 0)
                            .toFixed(2)
                        )}
                      </TableCell>
                      <TableCell sx={styles.tableCellTotal}>
                        {formatNumberWithCommas(
                          rows
                            .reduce((sum, row) => sum + +row.lowTaxGrocery, 0)
                            .toFixed(2)
                        )}
                      </TableCell>
                      <TableCell sx={styles.tableCellTotal}>
                        {formatNumberWithCommas(
                          rows
                            .reduce((sum, row) => sum + +row.foodIngredients, 0)
                            .toFixed(2)
                        )}
                      </TableCell>
                      <TableCell sx={styles.tableCellTotal}>
                        {formatNumberWithCommas(
                          rows
                            .reduce((sum, row) => sum + +row.iceCream, 0)
                            .toFixed(2)
                        )}
                      </TableCell>
                      <TableCell sx={styles.tableCellTotal}>
                        {formatNumberWithCommas(
                          rows
                            .reduce((sum, row) => sum + +row.bottledWater, 0)
                            .toFixed(2)
                        )}
                      </TableCell>
                      <TableCell sx={styles.tableCellTotal}>
                        {formatNumberWithCommas(
                          rows
                            .reduce(
                              (sum, row) => sum + +row.fruitsVegetables,
                              0
                            )
                            .toFixed(2)
                        )}
                      </TableCell>
                      <TableCell sx={styles.tableCellTotal}>
                        {formatNumberWithCommas(
                          rows
                            .reduce((sum, row) => sum + +row.softDrinks, 0)
                            .toFixed(2)
                        )}
                      </TableCell>
                      <TableCell sx={styles.tableCellTotal}>
                        {formatNumberWithCommas(
                          rows
                            .reduce((sum, row) => sum + +row.coffee, 0)
                            .toFixed(2)
                        )}
                      </TableCell>
                      <TableCell sx={styles.tableCellTotal}>
                        {formatNumberWithCommas(
                          rows
                            .reduce((sum, row) => sum + +row.chips, 0)
                            .toFixed(2)
                        )}
                      </TableCell>
                      <TableCell sx={styles.tableCellTotal}>
                        {formatNumberWithCommas(
                          rows
                            .reduce((sum, row) => sum + +row.dairy, 0)
                            .toFixed(2)
                        )}
                      </TableCell>
                      <TableCell sx={styles.tableCellTotal}>
                        {formatNumberWithCommas(
                          rows
                            .reduce((sum, row) => sum + +row.rawMeet, 0)
                            .toFixed(2)
                        )}
                      </TableCell>
                      <TableCell sx={styles.tableCellTotal}>
                        {formatNumberWithCommas(
                          rows
                            .reduce((sum, row) => sum + +row.otherLow, 0)
                            .toFixed(2)
                        )}
                      </TableCell>
                      <TableCell sx={styles.tableCellTotal}>
                        {formatNumberWithCommas(
                          rows
                            .reduce((sum, row) => sum + +row.totalLowTax, 0)
                            .toFixed(2)
                        )}
                      </TableCell>
                      <TableCell sx={styles.tableCellTotal}>
                        {formatNumberWithCommas(
                          rows
                            .reduce((sum, row) => sum + +row.totalSalesTax, 0)
                            .toFixed(2)
                        )}
                      </TableCell>
                      <TableCell sx={styles.tableCellTotal}>
                        {formatNumberWithCommas(
                          rows
                            .reduce((sum, row) => sum + +row.netSalesTax, 0)
                            .toFixed(2)
                        )}
                      </TableCell>
                      <TableCell sx={styles.tableCellTotal}>
                        {formatNumberWithCommas(
                          rows
                            .reduce(
                              (sum, row) => sum + +calculateRowTotal(row),
                              0
                            )
                            .toFixed(2)
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[50, 100, 150, 200]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      )}
      {reportType === 'Summary' && (
        <Box sx={{ mx: 5, width: 'inherit', overflow: 'hidden' }}>
          <Paper sx={{ width: '100%', overflow: 'auto' }} elevation={3}>
            <TableContainer style={{ marginLeft: '0px' }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {summaryColumns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          fontWeight: '800',
                          color: '#1976D2',
                          backgroundColor: '#f5f5f5'
                        }}
                        sx={styles.tableCell}
                      >
                        <Typography>{column.label}</Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                        >
                          <TableCell sx={styles.tableCell}>
                            {row.month.substring(0, 3)}-{row.year}
                          </TableCell>
                          <TableCell sx={styles.tableCell}>
                            {row.gasolineGallon}
                          </TableCell>
                          <TableCell sx={styles.tableCell}>
                            {row.gasolineAmount}
                          </TableCell>

                          <TableCell sx={styles.tableCell}>
                            {row.totalExempt}
                          </TableCell>

                          <TableCell sx={styles.tableCell}>
                            {row.totalHighTax}
                          </TableCell>

                          <TableCell sx={styles.tableCell}>
                            {row.totalLowTax}
                          </TableCell>
                          <TableCell sx={styles.tableCell}>
                            {row.totalSalesTax}
                          </TableCell>
                          <TableCell sx={styles.tableCell}>
                            {row.netSalesTax}
                          </TableCell>
                          <TableCell sx={styles.tableCellTotal}>
                            {formatNumberWithCommas(calculateRowTotal(row))}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {rows.length > 0 && (
                    <TableRow>
                      <TableCell sx={styles.tableCellTotal}>Total</TableCell>
                      <TableCell sx={styles.tableCellTotal}>
                        {formatNumberWithCommas(
                          rows
                            .reduce((sum, row) => sum + +row.gasolineGallon, 0)
                            .toFixed(2)
                        )}
                      </TableCell>
                      <TableCell sx={styles.tableCellTotal}>
                        {formatNumberWithCommas(
                          rows
                            .reduce((sum, row) => sum + +row.gasolineAmount, 0)
                            .toFixed(2)
                        )}
                      </TableCell>

                      <TableCell sx={styles.tableCellTotal}>
                        {formatNumberWithCommas(
                          rows
                            .reduce((sum, row) => sum + +row.totalExempt, 0)
                            .toFixed(2)
                        )}
                      </TableCell>

                      <TableCell sx={styles.tableCellTotal}>
                        {formatNumberWithCommas(
                          rows
                            .reduce((sum, row) => sum + +row.totalHighTax, 0)
                            .toFixed(2)
                        )}
                      </TableCell>

                      <TableCell sx={styles.tableCellTotal}>
                        {formatNumberWithCommas(
                          rows
                            .reduce((sum, row) => sum + +row.totalLowTax, 0)
                            .toFixed(2)
                        )}
                      </TableCell>
                      <TableCell sx={styles.tableCellTotal}>
                        {formatNumberWithCommas(
                          rows
                            .reduce((sum, row) => sum + +row.totalSalesTax, 0)
                            .toFixed(2)
                        )}
                      </TableCell>
                      <TableCell sx={styles.tableCellTotal}>
                        {formatNumberWithCommas(
                          rows
                            .reduce((sum, row) => sum + +row.netSalesTax, 0)
                            .toFixed(2)
                        )}
                      </TableCell>
                      <TableCell sx={styles.tableCellTotal}>
                        {formatNumberWithCommas(
                          rows
                            .reduce(
                              (sum, row) => sum + +calculateRowTotal(row),
                              0
                            )
                            .toFixed(2)
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[50, 100, 150, 200]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      )}
    </>
  );
};

export default SalesReport;

const calculateRowTotal = (row: any) => {
  return (
    +row.gasolineAmount +
    +row.carWash +
    +row.newsPaper +
    +row.ebt +
    +row.exemptFood +
    +row.saleTaxHoliday +
    +row.otherExempt +
    +row.highTaxGrocery +
    +row.beer +
    +row.cigarettes +
    +row.hotFoodDeli +
    +row.fountainDrink +
    +row.hotCoffee +
    +row.tobacco +
    +row.novelties +
    +row.phoneCards +
    +row.cbdDelta +
    +row.candies +
    +row.wine +
    +row.otherHigh +
    +row.lowTaxGrocery +
    +row.foodIngredients +
    +row.iceCream +
    +row.bottledWater +
    +row.fruitsVegetables +
    +row.softDrinks +
    +row.coffee +
    +row.chips +
    +row.dairy +
    +row.rawMeet +
    +row.otherLow
  ).toFixed(2);
};
