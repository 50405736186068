import {
  Box,
  Button,
  Grid,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Close as CloseIcon } from '@mui/icons-material';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EmailIcon from '@mui/icons-material/Email';
import * as XLSX from 'xlsx';
import {
  addEmployessPayroll,
  getEmployeePayrollsByFederaltaxIdAndMonthAndYear,
  previousMonthPayrollEmail
} from 'src/services/payrollService';
import { NotificationManager } from 'react-notifications';

import ConfirmationDialogue from '../Dialog/confirmationDialogue';

const AddPayroll = ({ open, onClose }) => {
  const [excelData, setExcelData] = useState([]);
  const fileInputRef = useRef(null);
  const [fileSelected, setFileSelected] = useState(false);
  const [payrollData, setPayrollData] = useState([]);
  const [ComponayFederalTaxId, setComponayFederalTaxId] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [saveAndEmail, setSaveAndEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const handleBoxClick = () => {
    if (fileInputRef.current && !fileSelected) {
      fileInputRef.current.click();
    }
  };

  const handleDrop = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    const file = event.dataTransfer.files[0];

    if (file) {
      manageFile(file);
    }
  };

  const handleDragOver = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleFileChange = (event: any) => {
    try {
      const file = event.target.files[0];
      manageFile(file);
    } catch (error) {
      console.log(error);

      NotificationManager.error('Error', 'Error', 3000);
    }
  };
  const manageFile = (file: any) => {
    try {
      if (!file) {
        return;
      }
      const reader = new FileReader();
      const name = file.name.split(',');
      setComponayFederalTaxId(name[0]);
      setMonth(name[1].split('.')[0].substring(0, 2));
      setYear(name[1].split('.')[0].substring(2, 6));

      let date = new Date(+year, +month - 1, 1);

      reader.onload = (e) => {
        const workbook = XLSX.read(e.target.result, { type: 'binary' });

        const sheetName = workbook.SheetNames[0]; // Assuming you want to read the first sheet
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        setExcelData(jsonData);
        setFileSelected(true);
        setPayrollData(convertRawJsonToPayroll(jsonData));
      };

      reader.readAsArrayBuffer(file);
    } catch (error) {
      console.log(error);

      NotificationManager.error('Error', 'Error', 3000);
    }
  };
  const handleClose = () => {
    setExcelData([]);
    setFileSelected(false);
    onClose();
  };

  const handleBack = () => {
    setFileSelected(false);
    setPayrollData([]);
    setExcelData([]);
  };
  const handleSave = async () => {
    try {
      setLoading(true);
      const payload = {
        federalTaxId: ComponayFederalTaxId,
        month: month,
        year: year,
        employeePayrolls: payrollData
      };
      
      const payrolls = await getEmployeePayrollsByFederaltaxIdAndMonthAndYear({
        federalTaxId: payload.federalTaxId,
        month: +payload.month,
        year: +year,
      });
      if(payrolls && payrolls.data.length > 0){
        console.log('hello');
        setConfirmationMessage("Payroll for this month already exists. Do you want to overwrite?");
        setConfirmation(true);
        return;
      }
      
      const addPayrollResponse = await addEmployessPayroll(payload as any);
      NotificationManager.success('Successfully uploaded Payrolls');
      handleClose();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleClose();
      NotificationManager.error('Failed to upload Payrolls', 'Error', 3000);
    }
  };
  const handleSaveAndEmail = async () => {
    setSaveAndEmail(true);
    try {
      setLoading(true);
      const payload = {
        federalTaxId: ComponayFederalTaxId,
        month: month,
        year: year,
        employeePayrolls: payrollData
      };

      const payrolls = await getEmployeePayrollsByFederaltaxIdAndMonthAndYear({
        federalTaxId: payload.federalTaxId,
        month: +payload.month,
        year: +year,
      });
      if(payrolls && payrolls.data.length > 0){
        console.log('hello');
        setConfirmationMessage("Payroll for this month already exists. Do you want to overwrite?");
        setConfirmation(true);
        return;
      }
      
      const addPayrollResponse = await addEmployessPayroll(payload as any);
      if (addPayrollResponse) {
        try {
          setLoading(true);
          const emailPayrollResponse = await previousMonthPayrollEmail({
            federalTaxId: ComponayFederalTaxId,
            month: +month,
            year: +year
          });
          NotificationManager.success(
            'Successfully uploaded Payrolls and emailed'
          );
          setLoading(false);
          handleClose();
        } catch (e) {
          setLoading(false);
          handleClose();
          NotificationManager.error('Failed to upload Payrolls', 'Error', 3000);
        }
      }
      setLoading(false);
      handleClose();
    } catch (e) {
      setLoading(false);
      handleClose();
      NotificationManager.error('Failed to upload Payrolls', 'Error', 3000);
    }
  };

  const handleOverWritePayroll = async () => {
    try {
      const payload = {
        federalTaxId: ComponayFederalTaxId,
        month: month,
        year: year,
        employeePayrolls: payrollData
      };
     
      const addPayrollResponse = await addEmployessPayroll(payload as any);
      if (addPayrollResponse && !!saveAndEmail) {
        try {
          const emailPayrollResponse = await previousMonthPayrollEmail({
            federalTaxId: ComponayFederalTaxId,
            month: +month,
            year: +year
          });
          NotificationManager.success(
            'Successfully uploaded Payrolls and emailed'
          );
          handleClose();
        } catch (e) {
          handleClose();
          NotificationManager.success('Successfully uploaded Payrolls');
          NotificationManager.error('Failed to Send Email', 'Error', 3000);
        }
      }
      handleClose();
      setConfirmation(false);
    } catch (e) {
      handleClose();
      setConfirmation(false);
      NotificationManager.error('Failed to upload Payrolls', 'Error', 3000);
    }
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backdropFilter: 'blur(8px)', // Adjust blur intensity as needed
          WebkitBackdropFilter: 'blur(8px)'
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '5%',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '90%',
            height: '85%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: '10px',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              display: 'none' // Hide scrollbar for Chrome/Safari
            },
            msOverflowStyle: 'none', // Hide scrollbar for IE and Edge
            scrollbarWidth: 'none',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <CloseIcon
            sx={{
              position: 'fixed',
              top: 10,
              right: 10,
              cursor: 'pointer',
              zIndex: 2
            }}
            onClick={handleClose}
          />

          <Box
            onClick={handleBoxClick}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 1,
              cursor: 'pointer'
            }}
          />

          {!fileSelected && (
            <>
              <CloudUploadOutlinedIcon fontSize="large" color="primary" />

              <h2>Please Upload XLS File</h2>
              <p>Drag and Drop or Click anywhere to upload</p>

              <input
                ref={fileInputRef}
                type="file"
                accept=".xls,.xlsx"
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
            </>
          )}

          {fileSelected ? (
            <>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <TableContainer
                      sx={{ overflowX: 'scroll', overflowY: 'auto', zIndex: 1 }}
                    >
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              sx={{ fontWeight: 'bold', color: '#1976D2' }}
                            >
                              Employee Name
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: 'bold', color: '#1976D2' }}
                            >
                              Federal Tax ID
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: 'bold', color: '#1976D2' }}
                            >
                              Gross Wages
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: 'bold', color: '#1976D2' }}
                            >
                              Social Security
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: 'bold', color: '#1976D2' }}
                            >
                              Medicare
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: 'bold', color: '#1976D2' }}
                            >
                              Federal WH
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: 'bold', color: '#1976D2' }}
                            >
                              State WH
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: 'bold', color: '#1976D2' }}
                            >
                              Local WH
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: 'bold', color: '#1976D2' }}
                            >
                              Tips
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: 'bold', color: '#1976D2' }}
                            >
                              Benefit & Deduction
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: 'bold', color: '#1976D2' }}
                            >
                              Net Pay
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: 'bold', color: '#1976D2' }}
                            >
                              FUTA Taxable Wages
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: 'bold', color: '#1976D2' }}
                            >
                              FUTA Tax
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: 'bold', color: '#1976D2' }}
                            >
                              FUTA Credit Reduction
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: 'bold', color: '#1976D2' }}
                            >
                              SUTA Taxable Wages
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: 'bold', color: '#1976D2' }}
                            >
                              SUTA Tax
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {payrollData.map((row, rowIndex) => (
                            <TableRow key={rowIndex}>
                              <TableCell>{row.employeeName}</TableCell>
                              <TableCell>{row.federalTaxId}</TableCell>
                              <TableCell>{row.grossWages}</TableCell>
                              <TableCell>{row.socSec}</TableCell>
                              <TableCell>{row.medicare}</TableCell>
                              <TableCell>{row.federalWH}</TableCell>
                              <TableCell>{row.stateWH}</TableCell>
                              <TableCell>{row.localWH}</TableCell>
                              <TableCell>{row.tips}</TableCell>
                              <TableCell>{row.benefitDeduction}</TableCell>
                              <TableCell>{row.netPay}</TableCell>
                              <TableCell>{row.futaTaxable}</TableCell>
                              <TableCell>{row.futaTax}</TableCell>
                              <TableCell>{row.futaCredit}</TableCell>
                              <TableCell>{row.sutaTaxable}</TableCell>
                              <TableCell>{row.sutaTax}</TableCell>
                            </TableRow>
                          ))}
                              <TableRow  >
                              <TableCell sx={{fontWeight:800}}>Total</TableCell>
                              <TableCell>{}</TableCell>
                              <TableCell sx={{fontWeight:700}}>{payrollData.reduce((total, entry) => total + entry.grossWages, 0).toFixed(2)}</TableCell>
                              <TableCell sx={{fontWeight:700}}>{payrollData.reduce((total, entry) => total + entry.socSec, 0).toFixed(2)}</TableCell>
                              <TableCell sx={{fontWeight:700}}>{payrollData.reduce((total, entry) => total + entry.medicare, 0).toFixed(2)}</TableCell>
                              <TableCell sx={{fontWeight:700}}>{payrollData.reduce((total, entry) => total + entry.federalWH, 0).toFixed(2)}</TableCell>
                              <TableCell sx={{fontWeight:700}}>{payrollData.reduce((total, entry) => total + entry.stateWH, 0).toFixed(2)}</TableCell>
                              <TableCell sx={{fontWeight:700}}>{payrollData.reduce((total, entry) => total + entry.localWH, 0).toFixed(2)}</TableCell>
                              <TableCell sx={{fontWeight:700}}>{payrollData.reduce((total, entry) => total + entry.tips, 0).toFixed(2)}</TableCell>
                              <TableCell sx={{fontWeight:700}}>{payrollData.reduce((total, entry) => total + entry.benefitDeduction, 0).toFixed(2)}</TableCell>
                              <TableCell sx={{fontWeight:700}}>{payrollData.reduce((total, entry) => total + entry.netPay, 0).toFixed(2)}</TableCell>
                              <TableCell sx={{fontWeight:700}}>{payrollData.reduce((total, entry) => total + entry.futaTaxable, 0).toFixed(2)}</TableCell>
                              <TableCell sx={{fontWeight:700}}>{payrollData.reduce((total, entry) => total + entry.futaTax, 0).toFixed(2)}</TableCell>
                              <TableCell sx={{fontWeight:700}}>{payrollData.reduce((total, entry) => total + entry.futaCredit, 0).toFixed(2)}</TableCell>
                              <TableCell sx={{fontWeight:700}}>{payrollData.reduce((total, entry) => total + entry.sutaTaxable, 0).toFixed(2)}</TableCell>
                              <TableCell sx={{fontWeight:700}}>{payrollData.reduce((total, entry) => total + entry.sutaTax, 0).toFixed(2)}</TableCell>
                            </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        color: '#1976D2',
                        mt: 2,
                        fontSize: '1.3rem'
                      }}
                    >
                      {' '}
                      Total Rows: {payrollData.length}
                    </Typography>
                  </Box>
                  <Grid
                    container
                    sx={{ position: 'absolute', bottom: '20px', width: '92%' }}
                  >
                    <Grid
                      item
                      xs={6}
                      sx={{ display: 'flex', justifyContent: 'flex-start' }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleBack}
                        size="medium"
                        sx={{ fontSize: '1rem', zIndex: 2 }}
                      >
                        Back
                        <ArrowBackIcon sx={{ ml: '4px' }} fontSize="medium" />
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        zIndex: 2
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                        size="medium"
                        sx={{ fontSize: '1rem', marginRight: '20px' }}
                        disabled={payrollData.length === 0 || loading}
                      >
                        Save
                        <SaveOutlinedIcon
                          sx={{ ml: '4px' }}
                          fontSize="medium"
                        />
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSaveAndEmail}
                        size="medium"
                        sx={{ fontSize: '1rem' }}
                        disabled={payrollData.length === 0 || loading}
                      >
                        Approve And Email
                        <EmailIcon sx={{ ml: '4px' }} fontSize="medium" />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : null}
        </Box>
      </Modal>
      <ConfirmationDialogue open={confirmation} close={()=>{setConfirmation(false); handleClose();} } onConfirm={()=>{handleOverWritePayroll()}} confirmationMessage={confirmationMessage} />
    </>
  );
};

export default AddPayroll;

export const convertRawJsonToPayroll = (rows: any) => {
  let nonemptyRows: any[] = rows.filter((row: any) => row.length > 0);
  const name = nonemptyRows[0][0];
  const dates = nonemptyRows[1][0];
  const totalsData = nonemptyRows[nonemptyRows.length - 2].filter(
    (cell: any) => cell !== null
  );
  nonemptyRows.splice(0, 4);
  nonemptyRows.splice(nonemptyRows.length - 4, 4);
  const finalJsonData = [];

  nonemptyRows = nonemptyRows.filter((row: any) => row.length > 0);
  for (let i = 0; i < nonemptyRows.length; i++) {
    nonemptyRows[i] = (nonemptyRows[i] as any[]).filter(
      (cell: any) => cell !== null
    );
  }

  for (let i = 0; i < nonemptyRows.length; i += 3) {
    finalJsonData.push({
      employeeName: nonemptyRows[i][0].split(' ---')[0],
      federalTaxId: nonemptyRows[i][1].split(': ')[1],
      grossWages: nonemptyRows[i + 1][2],
      socSec: nonemptyRows[i + 1][3],
      medicare: nonemptyRows[i + 1][4],
      federalWH: nonemptyRows[i + 1][5],
      stateWH: nonemptyRows[i + 1][6],
      localWH: nonemptyRows[i + 1][7],
      tips: nonemptyRows[i + 1][8],
      benefitDeduction: nonemptyRows[i + 1][9],
      netPay: nonemptyRows[i + 1][10],
      futaTaxable: nonemptyRows[i + 2][0],
      futaTax: nonemptyRows[i + 2][1],
      futaCredit: nonemptyRows[i + 2][2],
      sutaTaxable: nonemptyRows[i + 2][3],
      sutaTax: nonemptyRows[i + 2][4]
    });
  }
  return finalJsonData;
};
