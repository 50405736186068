import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  InputLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { BarLoader } from 'react-spinners';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SearchIcon from '@mui/icons-material/Search';
import moment from 'moment';
import { getPayrollClients } from 'src/services/clientService';
import { getClientWisePayrollsByParams } from 'src/services/payrollService';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { NotificationManager } from 'react-notifications';
import { formatClientId } from 'src/utils/helperFunctions';
import ViewPayroll from '../viewPayroll/ViewPayroll';

interface Column {
  id:
    | 'clientID'
    | 'state'
    | 'legalBusinessName'
    | 'doingBusinessAs'
    | 'monthYear'
    | 'taxPayble'
    | 'totalSales'
    | 'totalGasoline'
    | 'totalExempt'
    | 'totalHighTax'
    | 'totalLowTax'
    | 'Status'
    | 'Actions';
  label: string;
  minWidth?: number;
  align?: 'right';
  //   format?: (value: number) => string;
}

const columns = [
  { id: 'clientID', label: 'Client ID' },
  { id: 'state', label: 'State' },
  { id: 'legalBusinessName', label: 'Legal Business Name', minWidth: 150 },
  { id: 'doingBusinessAs', label: 'Doing Business As', minWidth: 130 },
  { id: 'monthYear', label: 'Month/Year' },
  { id: 'numberOfEmployees', label: 'Number of Employees' },
  { id: 'totalGrossWages', label: 'Gross Wages' },
  { id: 'totalSocSec', label: 'Soc. Sec.' },
  { id: 'totalMedicare', label: 'Medicare' },
  { id: 'totalFederalWH', label: 'Federal WH' },
  { id: 'tax941', label: '941 Tax' },
  { id: 'totalFuta', label: 'FUTA Tax' },
  { id: 'totalStateWH', label: 'State WH' },
  { id: 'totalSuta', label: 'SUTA Tax' },
  { id: 'Actions', label: 'Actions' }
];
const PayrollReport = () => {
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedClient, setSelectedClient]: any = useState(null);
  const [selectedStartMonth, setSelectedStartMonth] = useState(null);
  const [selectedEndMonth, setSelectedEndMonth] = useState(null);
  const [payrolls, setPayrolls] = useState([]);
  const [selectedPayroll, setSelectedPayroll] = useState([]);
  const [isViewPayrollModal, setIsViewPayrolModal] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const styles = {
    cell: {
      border: '1px solid #E3E1D9',
      borderCollapse: 'collapse',
      padding: '6px'
    },
    cellTotal: {
      border: '1px solid #E3E1D9',
      borderCollapse: 'collapse',
      padding: '6px',
      fontWeight: 'bold'
    }
  };

  const handleClientChange = async (value) => {
    setSelectedClient(value);
  };

  const handleClear = () => {
    setSelectedClient(null);
    // setSelectedYear(null);
    setSelectedStartMonth(null);
    setSelectedEndMonth(null);
  };

  const getClients = async () => {
    try {
      const data = await getPayrollClients();
      console.log('data::', data);
      setClients(data.data);
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };
  const getPayrolls = async () => {
    if(!selectedClient || !selectedStartMonth || !selectedEndMonth){
      NotificationManager.error(`Please select all the required fields`, 'Error', 3000);
      return;
    }
    setLoading(true);
    console.log({ selectedClient, selectedStartMonth, selectedEndMonth });
    
    const payrollsResponse = await getClientWisePayrollsByParams({clientId: selectedClient?.id || 0,monthStart:moment(selectedStartMonth).format('YYYY-MM') || '0', monthEnd:moment(selectedEndMonth).format('YYYY-MM') || '0'});

    const sortedResponse = sortByYearAndMonth(payrollsResponse.data);
    setPayrolls(sortedResponse);

    setLoading(false);
  };

  const sortByYearAndMonth = (data: any) => {
    return data.sort((a, b) => {
      // Compare years first
      const yearDifference = a.year - b.year;
      if (yearDifference !== 0) {
        return yearDifference;
      }
      // If years are the same, compare months
      return a.month - b.month;
    });
  };

  function handlePayrollView(index: number): void {
    try {
      setLoading(true);
      setSelectedPayroll(payrolls[index].employeePayrolls);
      setIsViewPayrolModal(true);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching employees:', error);
    }
  }
  const closeModal = () => {
    setIsViewPayrolModal(false);
    getPayrolls();
  };

  const handleExportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Styled Sheet');

    worksheet.addRow([]); // Add an empty row
    worksheet.addRow([
      `Legal Name: ${
        (selectedClient && selectedClient.legalBusinessName) || ''
      }`
    ]); // Row for Legal Business Name
    worksheet.addRow([
      `DBA: ${(selectedClient && selectedClient.doingBusinessAs) || ''}`
    ]); // Row for DBA
    worksheet.addRow([
      `Location:  ${(selectedClient && selectedClient.streetAddress1) || ''}, ${
        (selectedClient && selectedClient.city) || ''
      }, ${(selectedClient && selectedClient.state) || ''}, ${
        (selectedClient && selectedClient.zipCode) || ''
      } `
    ]); // Row for Location
    worksheet.addRow([
      `Report Period:  ${moment(selectedStartMonth).format(
        'MMM-YY'
      )} to ${moment(selectedEndMonth).format('MMM-YY')}`
    ]); // Add an empty row
    worksheet.addRow([]); // Add an empty row
    worksheet.addRow([
      'Month/Year',
      'Number of Employees',
      'Gross Wages',
      'Soc. Sec.',
      'Medicare',
      'Federal WH',
      '941 Tax',
      'FUTA Tax',
      'State WH',
      'SUTA Tax'
    ]); 

    const legalName = worksheet.getCell('A2');
    const dba = worksheet.getCell('A3');
    const location = worksheet.getCell('A4');
    const reportPeriod = worksheet.getCell('A5');

    legalName.font = { bold: true, color: { argb: 'ff1c4e80' } };
    dba.font = { bold: true, color: { argb: 'ff1c4e80' } };
    location.font = { bold: true, color: { argb: 'ff1c4e80' } };
    reportPeriod.font = { bold: true, color: { argb: 'ff1c4e80' } };

    // Add color to complere row 7
    worksheet.getRow(7).eachCell((cell, colNumber) => {
      cell.font = { bold: true, color: { argb: 'ff1c4e80' } };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'ffc6eob4' }
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
      cell.alignment = { horizontal: 'center', vertical: 'middle' };
    });

    worksheet.columns = [
      { width: 10 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
    ];

    worksheet.mergeCells('A2:E2');// merging for Legal Business Name
    worksheet.mergeCells('A3:E3');// merging for DBA
    worksheet.mergeCells('A4:E4');// merging for Location
    worksheet.mergeCells('A5:E5');// merging for Report Period


    // Add some data rows with different styles
    const data = [];
    payrolls.map((row) => {
      data.push([
        `${moment(new Date(`${row.month}-01-${row.year}`)).format('MMM-YYYY')}`,
        row.employeePayrolls && +row.employeePayrolls.length,
        row.employeePayrolls &&
          +row.employeePayrolls
            .reduce(
              (total, payroll) => total + parseFloat(payroll.grossWages),
              0
            )
            .toFixed(2),
        row.employeePayrolls &&
          +row.employeePayrolls
            .reduce((total, payroll) => total + parseFloat(payroll.socSec), 0)
            .toFixed(2),
        row.employeePayrolls &&
          +row.employeePayrolls
            .reduce((total, payroll) => total + parseFloat(payroll.medicare), 0)
            .toFixed(2),
        row.employeePayrolls &&
          +row.employeePayrolls
            .reduce(
              (total, payroll) => total + parseFloat(payroll.federalWH),
              0
            )
            .toFixed(2),
        row.employeePayrolls &&
          +(
            (row.employeePayrolls.reduce(
              (total, payroll) => total + parseFloat(payroll.socSec),
              0
            ) +
              row.employeePayrolls.reduce(
                (total, payroll) => total + parseFloat(payroll.medicare),
                0
              )) *
              2 +
            row.employeePayrolls.reduce(
              (total, payroll) => total + parseFloat(payroll.federalWH),
              0
            )
          ).toFixed(2),
        row.employeePayrolls &&
          +row.employeePayrolls
            .reduce((total, payroll) => total + parseFloat(payroll.futaTax), 0)
            .toFixed(2),
        row.employeePayrolls &&
          +row.employeePayrolls
            .reduce((total, payroll) => total + parseFloat(payroll.stateWH), 0)
            .toFixed(2),
        row.employeePayrolls &&
          +row.employeePayrolls
            .reduce((total, payroll) => total + parseFloat(payroll.sutaTax), 0)
            .toFixed(2)
      ]);
    });

    data.push([
      'Total',
      +payrolls.reduce((sum, row) => sum +  +row.employeePayrolls.length, 0).toFixed(2),

      +payrolls.reduce((sum, row) => sum +  +(row.employeePayrolls &&
        +row.employeePayrolls
          .reduce(
            (total, payroll) => total + parseFloat(payroll.grossWages),
            0
          )), 0).toFixed(2),

      +payrolls.reduce((sum, row) => sum +  +(row.employeePayrolls &&
        +row.employeePayrolls
          .reduce((total, payroll) => total + parseFloat(payroll.socSec), 0)
          .toFixed(2)), 0).toFixed(2),
      
      +payrolls.reduce((sum, row) => sum +  +(row.employeePayrolls &&
        +row.employeePayrolls
          .reduce((total, payroll) => total + parseFloat(payroll.medicare), 0)), 0).toFixed(2),

      +payrolls.reduce((sum, row) => sum +  +(row.employeePayrolls &&
        +row.employeePayrolls
          .reduce(
            (total, payroll) => total + parseFloat(payroll.federalWH),
            0
          )), 0).toFixed(2),

      +payrolls.reduce((sum, row) => sum +  +(row.employeePayrolls &&
        +(
          (row.employeePayrolls.reduce(
            (total, payroll) => total + parseFloat(payroll.socSec),
            0
          ) +
            row.employeePayrolls.reduce(
              (total, payroll) => total + parseFloat(payroll.medicare),
              0
            )) *
            2 +
          row.employeePayrolls.reduce(
            (total, payroll) => total + parseFloat(payroll.federalWH),
            0
          )
        )), 0).toFixed(2),

      +payrolls.reduce((sum, row) => sum +  +(row.employeePayrolls &&
        +row.employeePayrolls
          .reduce((total, payroll) => total + parseFloat(payroll.futaTax), 0)), 0).toFixed(2),

      +payrolls.reduce((sum, row) => sum +  +(row.employeePayrolls &&
        +row.employeePayrolls
          .reduce((total, payroll) => total + parseFloat(payroll.stateWH), 0)), 0).toFixed(2),

      +payrolls.reduce((sum, row) => sum +  +(row.employeePayrolls &&
        +row.employeePayrolls
          .reduce((total, payroll) => total + parseFloat(payroll.sutaTax), 0)), 0).toFixed(2),

    ]);


    data.forEach((item) => {
      const dataRow = worksheet.addRow(item);
      dataRow.eachCell((cell) => {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
        cell.alignment = { horizontal: 'center', vertical: 'middle' };
      });
    });

    // Generate buffer and save the file
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(
      new Blob([buffer]),
      `${formatClientId(selectedClient?.id || 0)}-Payroll-summary-${moment(
        selectedStartMonth
      ).format('MMM-YY')}-${moment(selectedEndMonth).format('MMM-YY')}.xlsx`
    );
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      await getClients();
    };
    fetchInitialData();
  }, []);

  return (
    <>
      <Box my={2} mx={5}>
        {loading && (
          <div className="loader-wrapper" style={{ textAlign: 'center' }}>
            <BarLoader color={'#1976D2'} loading={loading} width={'94vw'} />
          </div>
        )}
        <Typography
          sx={{
            textAlign: 'center',
            fontWeight: 'bold',
            color: '#1976D2',
            fontSize: '1.5em',
            my: 2
          }}
        >
          Payroll Summary
        </Typography>
        <Grid container spacing={2} mt={0}>
          <Grid item xs={12} md={3.5}>
            <InputLabel htmlFor="client">Client</InputLabel>
            <Autocomplete
              id="client"
              options={clients}
              getOptionLabel={(client) => client.legalBusinessName || ''}
              value={selectedClient}
              onChange={(_, newValue) => handleClientChange(newValue)}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" size="small" />
              )}
            />
          </Grid>
          <Grid item xs={6} md={1.5} mt={0}>
            <InputLabel htmlFor="startMonth">Start Month</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Box sx={{ width: '100%' }}>
                <DatePicker
                  views={['year', 'month']}
                  openTo="year"
                  value={selectedStartMonth}
                  inputFormat="MMM yyyy"
                  onChange={setSelectedStartMonth}
                  renderInput={(props) => (
                    <TextField {...props} style={{ width: '100%' }} />
                  )}
                  InputProps={{
                    size: 'small',
                    name: 'startMonth'
                  }}
                />
              </Box>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6} md={1.5} mt={0}>
            <InputLabel htmlFor="endMonth">End Month</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Box sx={{ width: '100%' }}>
                <DatePicker
                  views={['year', 'month']}
                  openTo="year"
                  value={selectedEndMonth}
                  inputFormat="MMM yyyy"
                  onChange={setSelectedEndMonth}
                  renderInput={(props) => (
                    <TextField {...props} style={{ width: '100%' }} />
                  )}
                  InputProps={{
                    size: 'small',
                    name: 'endMonth'
                  }}
                />
              </Box>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={3.5} sm={3} md={1} sx={{ mt: { md: 3 } }}>
            <Button
              variant="contained"
              color="warning"
              onClick={handleClear}
              sx={{ scale: { xs: '0.8', md: '1' } }}
            >
              Clear
            </Button>
          </Grid>
          <Grid item xs={4} sm={2} md={1} sx={{ mt: { md: 3 } }}>
            <Button
              variant="contained"
              color="primary"
              onClick={getPayrolls}
              sx={{ scale: { xs: '0.8', md: '1' } }}
            >
              Search
              <SearchIcon />
            </Button>
          </Grid>
          <Grid item xs={0.1} sm={0} md={2}></Grid>
          <Grid item xs={3.8} sm={2} md={1} sx={{ mt: { md: 3 } }}>
            <Button
              variant="contained"
              color="success"
              onClick={handleExportToExcel}
              sx={{ scale: { xs: '0.8', md: '1' } }}
            >
              Export
              <FileDownloadIcon />
            </Button>
          </Grid>
        </Grid>
        <Grid mx={3} my={2} width={'inherit'} container spacing={2}>
          <Grid item md={6}></Grid>

          <Grid item md={6}></Grid>
        </Grid>
      </Box>
      <Box mx={5} mt={3}>
        <Paper sx={{ width: '96vw', overflow: 'hidden' }} elevation={3}>
          <TableContainer sx={{ width: '100%', overflow: 'auto' }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      style={{
                        minWidth: column.minWidth,
                        fontWeight: '800',
                        color: '#1976D2',
                        border: '1px solid #E3E1D9',
                        borderCollapse: 'collapse',
                        padding: 5
                      }}
                    >
                      {/* <TableSortLabel>{column.label}</TableSortLabel> */}
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {payrolls
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      <TableCell sx={styles.cell}>
                        {formatClientId(+row.clientId)}
                      </TableCell>
                      <TableCell sx={styles.cell}>
                        {row.client && row.client.state}
                      </TableCell>
                      <TableCell sx={styles.cell}>
                        {row.client && row.client.legalBusinessName}
                      </TableCell>
                      <TableCell sx={styles.cell}>
                        {row.client && row.client.doingBusinessAs}
                      </TableCell>
                      <TableCell
                        sx={styles.cell}
                      >{`${moment(new Date(`${row.month}-01-${row.year}`)).format('MMM-YYYY')}`}</TableCell>
                      <TableCell sx={styles.cell}>
                        {row.employeePayrolls && row.employeePayrolls.length}
                      </TableCell>
                      <TableCell sx={styles.cell}>
                        {row.employeePayrolls &&
                          row.employeePayrolls
                            .reduce(
                              (total, payroll) =>
                                total + parseFloat(payroll.grossWages),
                              0
                            )
                            .toFixed(2)}
                      </TableCell>
                      <TableCell sx={styles.cell}>
                        {row.employeePayrolls &&
                          row.employeePayrolls
                            .reduce(
                              (total, payroll) =>
                                total + parseFloat(payroll.socSec),
                              0
                            )
                            .toFixed(2)}
                      </TableCell>
                      <TableCell sx={styles.cell}>
                        {row.employeePayrolls &&
                          row.employeePayrolls
                            .reduce(
                              (total, payroll) =>
                                total + parseFloat(payroll.medicare),
                              0
                            )
                            .toFixed(2)}
                      </TableCell>
                      <TableCell sx={styles.cell}>
                        {row.employeePayrolls &&
                          row.employeePayrolls
                            .reduce(
                              (total, payroll) =>
                                total + parseFloat(payroll.federalWH),
                              0
                            )
                            .toFixed(2)}
                      </TableCell>
                      <TableCell sx={styles.cell}>
                        {row.employeePayrolls &&
                          (
                            (row.employeePayrolls.reduce(
                              (total, payroll) =>
                                total + parseFloat(payroll.socSec),
                              0
                            ) +
                              row.employeePayrolls.reduce(
                                (total, payroll) =>
                                  total + parseFloat(payroll.medicare),
                                0
                              )) *
                              2 +
                            row.employeePayrolls.reduce(
                              (total, payroll) =>
                                total + parseFloat(payroll.federalWH),
                              0
                            )
                          ).toFixed(2)}
                      </TableCell>
                      <TableCell sx={styles.cell}>
                        {row.employeePayrolls &&
                          row.employeePayrolls
                            .reduce(
                              (total, payroll) =>
                                total + parseFloat(payroll.futaTax),
                              0
                            )
                            .toFixed(2)}
                      </TableCell>
                      <TableCell sx={styles.cell}>
                        {row.employeePayrolls &&
                          row.employeePayrolls
                            .reduce(
                              (total, payroll) =>
                                total + parseFloat(payroll.stateWH),
                              0
                            )
                            .toFixed(2)}
                      </TableCell>
                      <TableCell sx={styles.cell}>
                        {row.employeePayrolls &&
                          row.employeePayrolls
                            .reduce(
                              (total, payroll) =>
                                total + parseFloat(payroll.sutaTax),
                              0
                            )
                            .toFixed(2)}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-around',
                          // marginTop: '20px',
                          ...styles.cell
                        }}
                      >
                        <IconButton
                          // onClick={() => handleEdit(index)}
                          style={{ color: '#1976D2', cursor: 'pointer' }}
                        >
                          <VisibilityIcon
                            onClick={() => handlePayrollView(index)}
                            style={{ color: '#1976D2', cursor: 'pointer' }}
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                <TableRow>
                  <TableCell
                    sx={styles.cellTotal}
                    style={{ textAlign: 'center' }}
                    colSpan={5}
                  >
                    Total
                  </TableCell>
                  <TableCell sx={styles.cellTotal}>
                    {
                      +payrolls
                        .reduce(
                          (sum, row) => sum + +row.employeePayrolls.length,
                          0
                        )
                        .toFixed(2)
                    }
                  </TableCell>
                  <TableCell sx={styles.cellTotal}>
                    {
                      +payrolls
                        .reduce(
                          (sum, row) =>
                            sum +
                            +(
                              row.employeePayrolls &&
                              +row.employeePayrolls.reduce(
                                (total, payroll) =>
                                  total + parseFloat(payroll.grossWages),
                                0
                              )
                            ),
                          0
                        )
                        .toFixed(2)
                    }
                  </TableCell>
                  <TableCell sx={styles.cellTotal}>
                    {
                     +payrolls.reduce((sum, row) => sum +  +(row.employeePayrolls &&
                      +row.employeePayrolls
                        .reduce((total, payroll) => total + parseFloat(payroll.socSec), 0)
                        .toFixed(2)), 0).toFixed(2)
                    }
                  </TableCell>
                  <TableCell sx={styles.cellTotal}>
                    {
                     +payrolls.reduce((sum, row) => sum +  +(row.employeePayrolls &&
                      +row.employeePayrolls
                        .reduce((total, payroll) => total + parseFloat(payroll.medicare), 0)), 0).toFixed(2)
                    }
                  </TableCell>
                  <TableCell sx={styles.cellTotal}>
                    {
                     +payrolls.reduce((sum, row) => sum +  +(row.employeePayrolls &&
                      +row.employeePayrolls
                        .reduce(
                          (total, payroll) => total + parseFloat(payroll.federalWH),
                          0
                        )), 0).toFixed(2)
                    }
                  </TableCell>
                  <TableCell sx={styles.cellTotal}>
                    {
                     +payrolls.reduce((sum, row) => sum +  +(row.employeePayrolls &&
                      +(
                        (row.employeePayrolls.reduce(
                          (total, payroll) => total + parseFloat(payroll.socSec),
                          0
                        ) +
                          row.employeePayrolls.reduce(
                            (total, payroll) => total + parseFloat(payroll.medicare),
                            0
                          )) *
                          2 +
                        row.employeePayrolls.reduce(
                          (total, payroll) => total + parseFloat(payroll.federalWH),
                          0
                        )
                      )), 0).toFixed(2)
                    }
                  </TableCell>
                  <TableCell sx={styles.cellTotal}>
                    {
                     +payrolls.reduce((sum, row) => sum +  +(row.employeePayrolls &&
                      +row.employeePayrolls
                        .reduce((total, payroll) => total + parseFloat(payroll.futaTax), 0)), 0).toFixed(2)
                    }
                  </TableCell>
                  <TableCell sx={styles.cellTotal}>
                    {
                     +payrolls.reduce((sum, row) => sum +  +(row.employeePayrolls &&
                      +row.employeePayrolls
                        .reduce((total, payroll) => total + parseFloat(payroll.stateWH), 0)), 0).toFixed(2)
                    }
                  </TableCell>
                  <TableCell sx={styles.cellTotal}>
                    {
                     +payrolls.reduce((sum, row) => sum +  +(row.employeePayrolls &&
                      +row.employeePayrolls
                        .reduce((total, payroll) => total + parseFloat(payroll.sutaTax), 0)), 0).toFixed(2)
                    }
                  </TableCell>
                  <TableCell sx={styles.cellTotal}></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[50, 100, 200]}
            count={payrolls.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      <ViewPayroll
        open={isViewPayrollModal}
        onClose={closeModal}
        payroll={selectedPayroll}
      />
    </>
  );
};

export default PayrollReport;
