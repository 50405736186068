import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Grid,
  TextField
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { BarLoader } from 'react-spinners';
import SalesForm from '../SalesForm/salesForm';
import ViewSales from '../ViewSales/viewSales';
import { deleteSales, getAllSales } from 'src/services/salesService';
import { formatNumber } from 'src/utils/helperFunctions';
import { NotificationManager } from 'react-notifications';
import ConfirmationDialogue from '../Dialog/confirmationDialogue';
import SearchBarWithCheckbox from '../HelperComponents/SearchBarWithCheckbox';
import { formatClientId } from 'src/utils/helperFunctions';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import moment from 'moment';



interface Column {
  id:
    | 'clientID'
    | 'state'
    | 'legalBusinessName'
    | 'doingBusinessAs'
    | 'monthYear'
    | 'taxPayble'
    | 'totalSales'
    | 'totalGasoline'
    | 'totalExempt'
    | 'totalHighTax'
    | 'totalLowTax'
    | 'Status'
    | 'Actions';
  label: string;
  minWidth?: number;
  align?: 'right';
}
const columnsForSearchbar: Column[] = [
  { id: 'clientID', label: 'Client ID'},
  { id: 'state', label: 'State'},
  { id: 'legalBusinessName', label: 'Legal Business Name'},
  { id: 'doingBusinessAs', label: 'Doing Business As'},
  { id: 'monthYear', label: 'Month/Year' }
];

const columns: Column[] = [
  { id: 'clientID', label: 'Client ID' },
  { id: 'state', label: 'State' },
  { id: 'legalBusinessName', label: 'Legal Business Name', minWidth: 160 },
  { id: 'doingBusinessAs', label: 'Doing Business As', minWidth: 130 },
  { id: 'monthYear', label: 'Month/Year' },
  { id: 'taxPayble', label: 'Tax Payble' },
  { id: 'totalSales', label: 'Total Sales' },
  { id: 'totalGasoline', label: 'Total Gasoline'},
  { id: 'totalExempt', label: 'Total Exempt' },
  { id: 'totalHighTax', label: 'Total High' },
  { id: 'totalLowTax', label: 'Total Low' },
  { id: 'Status', label: 'Status' },
  { id: 'Actions', label: 'Actions' }
];

const rows = [];

const styles = {
  cell:{
    border: '1px solid #E3E1D9',
    borderCollapse:'collapse',
    padding:'6px'
  }
}

const Sales = () => {
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isViewSalesModalOpen, setIsViewSalesModalOpen] = useState(false);
  const [sales, setSales] = useState([]);
  const [unFilteredSales, setUnFilteredSales] = useState([]);
  const [viewSale, setViewSale] = useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('asc');
  const [selectedSales, setSelectedSales] = useState(null);
  const [salesFromAction, setSalesFormAction] = useState(null);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [selectedStartMonth, setSelectedStartMonth] = useState(null);
  const [selectedEndMonth, setSelectedEndMonth] = useState(null);
  const [selectedColumns, setSelectedColumns] = useState<string[]>(['Legal Business Name','Doing Business As']);
  const [searchValue, setSearchValue] = useState<string>('');
  const user = JSON.parse(localStorage.getItem('user') || 'null');

  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    getSales();
  };

  const openViewSalesModal = () => {
    setIsViewSalesModalOpen(true);
  };
  const closeViewSalesModal = () => {
    setIsViewSalesModalOpen(false);
  };
  const handleView = async (id: number) => {
    console.log(id);
    console.log(sales[id]);

    setViewSale(sales[id]);
    setIsViewSalesModalOpen(true);
  };

  const handleEdit = (index: number) => {
    console.log(index, 'indexxxxx');
    setSelectedSales(sales[index]);
    console.log(sales[index]);
    setSalesFormAction('edit');
    setIsModalOpen(true);
  };

  const handleDelete = async (index: number) => {
    setSelectedSales(sales[index]);
    setOpenConfirmationDialog(true);
  };
  const closeConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getSales = async (start?: string, end?: string) => {
    let startDate = ""
    let endDate = ""
    if(selectedStartMonth && selectedEndMonth){
      startDate = moment(selectedStartMonth).format('YYYY-MM-DD');
      endDate = moment(selectedEndMonth).format('YYYY-MM-DD');
    }
    setLoading(true);

    try {
      if(startDate && endDate){
        const salesResponse: any = await getAllSales(startDate, endDate);
        setUnFilteredSales(salesResponse.data);

      }else {
        const salesResponse: any = await getAllSales();
        setUnFilteredSales(salesResponse.data);

      }
        // const salesResponse: any = await getAllSales(startDate, endDate);
        // setUnFilteredSales(salesResponse.data);

        // if (salesResponse && salesResponse.data) {
        //     setSales(salesResponse.data);
        //     setUnFilteredSales(salesResponse.data);
        // }
    } catch (error) {
        console.error("Error fetching sales data:", error);
    } finally {
        setLoading(false);
    }
};

  const deleteSale = async () => {
    try {
      setLoading(true);
      closeConfirmationDialog();
      await deleteSales(selectedSales.id);
      NotificationManager.success('Sale deleted successfully', 'Success', 3000);
      getSales();
      setLoading(false);
    } catch (error) {
      console.log({ error });
      closeConfirmationDialog();
      setLoading(false);
      NotificationManager.error(error.response.data.message, 'Error', 3000);
    }
    const response: any = await deleteSales(selectedSales.id);
    closeConfirmationDialog();
  };

  const handleSort = (columnId) => {
    // If the clicked column is already the current sorting column, reverse the sorting order
    // Otherwise, set the sorting column to the clicked column and reset the sorting order to ascending
    if (orderBy === columnId) {
      setOrder(order === 'asc' ? 'desc' : 'asc');
    } else {
      setOrderBy(columnId);
      setOrder('asc');
    }
  };

  const sortedSales = () => {
    if (orderBy === '') {
      return sales;
    }
    return sales.slice().sort((a, b) => {
      const aValue = a[orderBy];
      const bValue = b[orderBy];
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return (order === 'asc' ? 1 : -1) * aValue.localeCompare(bValue);
      }
      return (order === 'asc' ? 1 : -1) * (aValue - bValue);
    });
  };

  const sortTableDataByColumnsAndSearch = (
    data: any,
    selectedColumns: string[],
    searchValue: string
  ) => {
    if (searchValue.trim() === '' || !selectedColumns.length) {
      // Return all data if searchValue is empty
      return data;
    }

    const filteredData = data.filter((item: any) => {
      let isMatch = false;
      searchValue = searchValue.toLocaleLowerCase();

      selectedColumns.forEach((column) => {
        switch (column) {
          case 'Client ID':
            if (item.client.clientId.toLowerCase().includes(searchValue)) {
              isMatch = true;
            }
            break;
          case 'State':
            if (item.client.state.toLowerCase().includes(searchValue)) {
              isMatch = true;
            }
            break;
          case 'Legal Business Name':
            if (
              item.client.legalBusinessName.toLowerCase().includes(searchValue)
            ) {
              isMatch = true;
            }
            break;
          case 'Doing Business As':
            if (
              item.client.doingBusinessAs.toLowerCase().includes(searchValue)
            ) {
              isMatch = true;
            }
            break;
          case 'Month/Year':
            if (
              `${item.month.toLocaleLowerCase().slice(0, 3)}-${
                item.year
              }`.includes(searchValue)
            ) {
              isMatch = true;
            }
            break;
          // Add cases for other columns as needed
          default:
            break;
        }
      });

      return isMatch;
    });

    return filteredData;
  };

  const handleFilter = (Columns?: string[], Value?: string) => {
    setSales(
      sortTableDataByColumnsAndSearch(
        unFilteredSales,
        selectedColumns,
        searchValue
      )
    );
  };

  const handleExportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');

    // Define columns
    worksheet.columns = [
      { header: 'Client Id', key: 'id', width: 6 },
      { header: 'State', key: 'state', width: 30 },
      { header: 'Legal Business Name', key: 'legalBusinessName', width: 30 },
      { header: 'Doing Business As', key: 'doingBusinessAs', width: 30 },
      { header: 'Month/Year', key: 'month', width: 12 },
      { header: 'Tax Payable', key: 'taxPayable', width: 15 },
      { header: 'Total Sales', key: 'totalSales', width: 15},
      { header: 'Total Gasoline', key: 'totalGasoline', width: 15 },
      { header: 'Total Exempt', key: 'totalExempt', width: 15 },
      { header: 'Total High', key: 'totalHigh', width: 15 },
      { header: 'Total Low', key: 'totalLow', width: 15 },
      { header: 'Status', key: 'status', width: 30 }

    ];


    worksheet.getRow(1).font = { bold: true };
    worksheet.getRow(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFF00' }, // Yellow background
    };
    // Add rows
    sortedSales().forEach(row => {
      worksheet.addRow({
        id: row.client ? formatClientId(+row.client?.id) : 'N/A',
        state:   row.client?.state,
        legalBusinessName: row.client?.legalBusinessName,
        doingBusinessAs: row.client?.doingBusinessAs,
        month: `${row.month.slice(0, 3)}-${row.year}`,
        taxPayable: (+Math.round(row.netSalesTax)),
        totalSales: +row.totalSales,
        totalGasoline: +row.gasolineAmount,
        totalExempt: +row.totalExempt,
        totalHigh: +row.totalHighTax,
        totalLow: +row.totalLowTax,
        status: row.emailSent ? 'Approved' : 'Unapproved'
      });
    });

    // Save to file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, 'export.xlsx');
  };

  const handleClearFilter = () => {
    getSales();
    setSelectedStartMonth(null);
    setSelectedEndMonth(null);
    setSearchValue('');
    setSelectedColumns(['Legal Business Name','Doing Business As']);
  };

  useEffect(() => {
    const fetchData = async () => {
      if(!selectedStartMonth || !selectedEndMonth) return;
      let startDate = moment(selectedStartMonth).format('YYYY-MM-DD');
      let endDate = moment(selectedEndMonth).format('YYYY-MM-DD');
      setLoading(true);
      
      try {
        await getSales(startDate,endDate);
      } catch (error) {
        console.error('Error fetching data:', error);
        NotificationManager.error(
          `Error fetching data:, ${error}`,
          'Error',
          3000
        );

        setLoading(false);
      }
    };

    fetchData(); // Call the function to fetch data when component mounts
  }, [selectedStartMonth,selectedEndMonth]);

  useEffect(() => {
    handleFilter(selectedColumns, searchValue);

  }, [unFilteredSales]);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        await getSales();
      } catch (error) {
        console.error('Error fetching data:', error);
        NotificationManager.error(
          `Error fetching data:, ${error}`,
          'Error',
          3000
        );

        setLoading(false);
      }
    };

    fetchData(); // Call the function to fetch data when component mounts
  }, []);

  return (
    <Box sx={{ mt: 1, mx: '1vw' }}>
      {loading && (
        <div className="loader-wrapper" style={{ textAlign: 'center' }}>
          <BarLoader color={'#1976D2'} loading={loading} width={'96vw'} />
        </div>
      )}
      {/* <div>
      <p>Value in Component A: {value}</p>
      <button onClick={handleClick}>Change Value</button>
    </div> */}
      <Box
        sx={{ mt: 1 }}
        display={'flex'}
        justifyContent={'center'}
        width={'84vw'}
      >
        <Typography variant="h4" color={'#1976D2'}>
          SALES
        </Typography>
      </Box>
      <Grid container mt={2}>
        <Grid item xs={12} md={4}>
          <Box
            display="flex"
            justifyContent="flex-start"
            // width={{ xs: '56', sm: '42vw', md: '42vw' }}
            // marginTop={'15px'}
            marginRight={'5px'}
          >
            <SearchBarWithCheckbox
              columns={columnsForSearchbar}
              onSearch={handleFilter}
              value={searchValue}
              scolumns={selectedColumns}
              updateSearchValue={setSearchValue}
              updateSelectedColumns={setSelectedColumns}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={4} sx={{ ml: { md: 10 }, mt: { md: 0, xs: 2 } }}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={4} mt={0}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Box sx={{ width: '100%' }}>
                  <DatePicker
                    label="Start Month"
                    views={['year', 'month']}
                    openTo="year"
                    value={selectedStartMonth}
                    inputFormat="MMM yyyy"
                    onChange={setSelectedStartMonth}
                    renderInput={(props) => (
                      <TextField {...props} style={{ width: '100%' }} />
                    )}
                    InputProps={{
                      size: 'small',
                      name: 'startMonth'
                    }}
                  />
                </Box>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6} md={4} mt={0}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Box sx={{ width: '100%' }}>
                  <DatePicker
                    label="End Month"
                    views={['year', 'month']}
                    openTo="year"
                    value={selectedEndMonth}
                    inputFormat="MMM yyyy"
                    onChange={setSelectedEndMonth}
                    renderInput={(props) => (
                      <TextField {...props} style={{ width: '100%' }} />
                    )}
                    InputProps={{
                      size: 'small',
                      name: 'endMonth'
                    }}
                  />
                </Box>
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2} md={1} sx={{ mt: { md: 0, xs: 2 } }}>
          <Grid container display="flex" justifyContent="space-between">
            <Grid item xs={3.5}  md={4}>
            <Button
              variant="contained"
              color="warning"
              onClick={handleClearFilter}
              sx={{ scale: { xs: '0.8', md: '1' } }}
            >
              Clear
            </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8} md={2} sx={{ mt: { md: 0, xs: 2 }, ml: { xs: 3 } }}>
          <Grid container display="flex" justifyContent="space-between">
            <Grid item xs={4}  md={5}>
            <Button
              variant="contained"
              color="success"
              onClick={handleExportToExcel}
              sx={{ scale: { xs: '0.8', md: '1' } }}
            >
              Export
              <FileDownloadIcon />
            </Button>
            </Grid>
            <Grid item xs={8}  md={7}>
              <Button
                variant="contained"
                sx={{
                  padding: '10px',
                  scale: '0.9',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  '&:hover': {
                    transform: 'scale(0.9)',
                    transition: 'all 0.3s ease'
                  },
                  ml:{xs:5}
                  // width: '100px', // Fixed width for the button // Aligns the button to the end
                }}
                onClick={()=>{
                  setIsModalOpen(true)
                  setSalesFormAction('add')
                }}
              >
                Add Sales
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box mt={3}>
        <Paper sx={{ width: '97vw', overflow: 'hidden' }} elevation={3}>
          <TableContainer sx={{ width: '100%', overflow: 'auto' }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        fontWeight: '800',
                        color: '#1976D2',
                        cursor: 'pointer',
                        border: '1px solid #E3E1D9',
                        borderCollapse: 'collapse',
                        padding: 5
                      }}
                      onClick={() => handleSort(column.id)}
                    >
                      {/* <TableSortLabel
                        active={orderBy === column.id}
                        direction={
                          orderBy === column.id ? order : ('asc' as any)
                        }
                      > */}
                      {column.label}
                      {/* </TableSortLabel> */}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {sales
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      <TableCell sx={styles.cell}>
                        {row.client ? formatClientId(+row.client?.id) : 'N/A'}
                      </TableCell>
                      <TableCell sx={styles.cell}>
                        {row.client?.state}
                      </TableCell>
                      <TableCell sx={styles.cell}>
                        {row.client?.legalBusinessName}
                      </TableCell>
                      <TableCell sx={styles.cell}>
                        {row.client?.doingBusinessAs}
                      </TableCell>
                      <TableCell sx={styles.cell}>
                        {row.month.slice(0, 3)}-{row.year}
                      </TableCell>
                      <TableCell sx={styles.cell}>
                        {formatNumber(Math.round(row.netSalesTax))}
                      </TableCell>
                      <TableCell sx={styles.cell}>
                        {formatNumber(row.totalSales)}
                      </TableCell>
                      <TableCell sx={styles.cell}>
                        {formatNumber(row.gasolineAmount)}
                      </TableCell>
                      <TableCell sx={styles.cell}>
                        {formatNumber(row.totalExempt)}
                      </TableCell>
                      <TableCell sx={styles.cell}>
                        {formatNumber(row.totalHighTax)}
                      </TableCell>
                      <TableCell sx={styles.cell}>
                        {formatNumber(row.totalLowTax)}
                      </TableCell>
                      <TableCell sx={styles.cell}>
                        {' '}
                        <Box
                          component="div" // Set the component to "div" for non-clickable behavior
                          sx={{
                            color: row.emailSent ? 'green' : 'red'
                          }}
                        >
                          {row.emailSent ? 'Approved' : 'Unapproved'}
                        </Box>
                      </TableCell>
                      <TableCell sx={{ ...styles.cell }}>
                        <VisibilityIcon
                          onClick={() => handleView(index)}
                          style={{
                            color: '#1976D2',
                            cursor: 'pointer'
                          }}
                        />
                        <EditIcon
                          onClick={() => handleEdit(index)}
                          style={{
                            color: '#1976D2',
                            cursor: 'pointer',
                            marginLeft: '5px'
                          }}
                        />
                        {user.role == "admin" && (

                        <DeleteIcon
                          onClick={() => handleDelete(index)}
                          style={{
                            color: '#1976D2',
                            cursor: 'pointer',
                            marginLeft: '5px'
                          }}
                        />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[50, 100, 150, 200]}
            component="div"
            count={sortedSales().length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      <SalesForm
        open={isModalOpen}
        onClose={closeModal}
        sales={selectedSales}
        action={salesFromAction}
      />
      <ViewSales
        open={isViewSalesModalOpen}
        onClose={closeViewSalesModal}
        viewSales={viewSale}
      />
      {/* {loading && <Loader loading={loading} />} */}
      {
        <ConfirmationDialogue
          open={openConfirmationDialog}
          close={closeConfirmationDialog}
          confirmationMessage="Are you sure you want to delete this sales?"
          onConfirm={deleteSale}
        />
      }
    </Box>
  );
};
export default Sales;
