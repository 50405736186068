import {
  Modal,
  Typography,
  Box,
  Grid,
  TextField,
  InputLabel,
  Button,
  Autocomplete,
  InputAdornment,
  ButtonGroup
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BarLoader } from 'react-spinners';
import { Close as CloseIcon } from '@mui/icons-material';
import Dropdown from '../ClientsForm/dropdown';
import TextInput from '../ClientsForm/textInput';
import FormHeading from '../ClientsForm/formHeading';
import SalesDoubleInput from './salesDoubleInput';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import EmailIcon from '@mui/icons-material/Email';
import GradingIcon from '@mui/icons-material/Grading';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import {
  getClientById,
  getSalesTaxActiveClients
} from 'src/services/clientService';
import { NotificationManager } from 'react-notifications';
import {
  addSales,
  getPreviousSales,
  salesEmail
} from 'src/services/salesService';
import { validateSalesFormDate } from 'src/validations/salesForm';
import { formatNumberWithCommas } from './salesDoubleInput';
import { formatClientId } from 'src/utils/helperFunctions';
import ViewSales from '../ViewSales/viewSales';

const SalesForm = ({ open, onClose, sales, action }) => {
  const [clients, setClients] = useState([]);
  const [previousMonthSale, setPreviousMonthSale]: any = useState({});
  const [validationErrors, setValidationErrors]: any = useState({});
  const [viewSale, setViewSale] = useState(null);
  const [isViewSalesModalOpen, setIsViewSalesModalOpen] = useState(false);

  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() - 1); // Move to the previous month
  const previousMonth = currentDate.toLocaleString('default', {
    month: 'long'
  });
  const previousYear = currentDate.getFullYear();
  const yearsArray = getYears(new Date().getFullYear(), 1900);
  const monthArray = getMonths();

  const [loading, setLoading] = useState(false);
  const [salesData, setSalesData]: any = useState({
    client: {},
    year: previousYear,
    month: previousMonth,
    clientId: '',
    gasolineGallon: '',
    gasolineAmount: '',
    carWash: '',
    newsPaper: '',
    ebt: '',
    exemptFood: '',
    saleTaxHoliday: '',
    otherExempt: '',
    totalExempt: 0,
    highTaxGrocery: '',
    beer: '',
    cigarettes: '',
    hotFoodDeli: '',
    fountainDrink: '',
    hotCoffee: '',
    tobacco: '',
    novelties: '',
    phoneCards: '',
    cbdDelta: '',
    candies: '',
    wine: '',
    otherHigh: '',
    totalHighTax: 0,
    lowTaxGrocery: '',
    foodIngredients: '',
    iceCream: '',
    bottledWater: '',
    fruitsVegetables: '',
    softDrinks: '',
    coffee: '',
    chips: '',
    dairy: '',
    rawMeet: '',
    otherLow: '',
    totalLowTax: 0,
    totalSpecial: 0,
    fees911: '',
    clientStateHighTax: '',
    clientStateLowTax: '',
    clientCityCountyTax: '',
    clientCountyTax: '',
    clientCityTax: '',
    clientCBD: '',
    clientSpecial: '',
    clientLiquor: '',
    clientBeer: '',
    client911: '',
    clientDiscountRate: '',
    sumOfStateHighAndStateLowTax: '',
    totalSales: '',
    excessTaxCollected: '',
    credit: '',
    temporaryNotes: '',

    //calculated fields
    stateHighTax: '',
    stateLowTax: '',
    cityCountyTax: '',
    countyTax: '',
    cityTax: '',
    cbdTax: '',
    liquorTax: '',
    beerTax: '',
    total911: '',
    specialTax: '',
    totalSalesTax: '',
    discount: '',

    netSalesTax: '',
    sendEmail: false
  });

  const getClients = async () => {
    try {
      const data = await getSalesTaxActiveClients();
      setClients(data.data);
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };

  const sendSalesEmail = async () => {
    
    try {
      setLoading(true);
      const data = await salesEmail({
        year: salesData.year,
        month: salesData.month,
        clientId: salesData.client?.id
      });
      if (data.meta.status === 200) {
        NotificationManager.success('Email sent successfully');
        setSalesData({
          ...salesData,
          emailSent: true
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching clients:', error);
    }
  };

  /**
   * Retrieves previous sales data for a given client.
   *
   * @param {number} clientId - The ID of the client.
   * @return {Promise<void>} - A promise that resolves with the previous sales data.
   */
  const getPreviousSalesData = async ({ year, month, clientId }) => {
    try {
      if(!year || !month || !clientId) return;
      const data = await getPreviousSales({ year, month, clientId });
      if (data && data.meta && data.meta.status === 200) {
        setPreviousMonthSale(data.data);
      }
    } catch (error) {
      setPreviousMonthSale({});
      console.error('Error fetching clients:', error);
    }
  };
  const saveAndEmail = async (event) => {
    event.preventDefault();
    const sale: any = await handleSubmit(event);
    if(sale && sale.meta && sale.meta.status === 200){
      await sendSalesEmail();
    };
    
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const isValid = await validateFields();
      // const isValid = true;

      if (isValid) {
        const data = {
          clientId: salesData.client.id,
          year: salesData.year,
          month: salesData.month,
          gasolineGallon: removeCommasAndConvertToFloat(
            salesData.gasolineGallon
          ),
          gasolineAmount: removeCommasAndConvertToFloat(
            salesData.gasolineAmount
          ),
          carWash: removeCommasAndConvertToFloat(salesData.carWash),
          newsPaper: removeCommasAndConvertToFloat(salesData.newsPaper),
          ebt: removeCommasAndConvertToFloat(salesData.ebt),
          exemptFood: removeCommasAndConvertToFloat(salesData.exemptFood),
          saleTaxHoliday: removeCommasAndConvertToFloat(
            salesData.saleTaxHoliday
          ),
          otherExempt: removeCommasAndConvertToFloat(salesData.otherExempt),
          totalExempt: removeCommasAndConvertToFloat(salesData.totalExempt),
          highTaxGrocery: removeCommasAndConvertToFloat(
            salesData.highTaxGrocery
          ),
          beer: removeCommasAndConvertToFloat(salesData.beer),
          cigarettes: removeCommasAndConvertToFloat(salesData.cigarettes),
          hotFoodDeli: removeCommasAndConvertToFloat(salesData.hotFoodDeli),
          fountainDrink: removeCommasAndConvertToFloat(salesData.fountainDrink),
          hotCoffee: removeCommasAndConvertToFloat(salesData.hotCoffee),
          tobacco: removeCommasAndConvertToFloat(salesData.tobacco),
          novelties: removeCommasAndConvertToFloat(salesData.novelties),
          phoneCards: removeCommasAndConvertToFloat(salesData.phoneCards),
          cbdDelta: removeCommasAndConvertToFloat(salesData.cbdDelta),
          candies: removeCommasAndConvertToFloat(salesData.candies),
          wine: removeCommasAndConvertToFloat(salesData.wine),
          otherHigh: removeCommasAndConvertToFloat(salesData.otherHigh),
          totalHighTax: removeCommasAndConvertToFloat(salesData.totalHighTax),
          lowTaxGrocery: removeCommasAndConvertToFloat(salesData.lowTaxGrocery),
          foodIngredients: removeCommasAndConvertToFloat(
            salesData.foodIngredients
          ),
          iceCream: removeCommasAndConvertToFloat(salesData.iceCream),
          bottledWater: removeCommasAndConvertToFloat(salesData.bottledWater),
          fruitsVegetables: removeCommasAndConvertToFloat(
            salesData.fruitsVegetables
          ),
          softDrinks: removeCommasAndConvertToFloat(salesData.softDrinks),
          coffee: removeCommasAndConvertToFloat(salesData.coffee),
          chips: removeCommasAndConvertToFloat(salesData.chips),
          dairy: removeCommasAndConvertToFloat(salesData.dairy),
          rawMeet: removeCommasAndConvertToFloat(salesData.rawMeet),
          otherLow: removeCommasAndConvertToFloat(salesData.otherLow),
          totalLowTax: removeCommasAndConvertToFloat(salesData.totalLowTax),
          totalSales: removeCommasAndConvertToFloat(salesData.totalSales),
          stateHighTax: removeCommasAndConvertToFloat(salesData.stateHighTax),
          stateLowTax: removeCommasAndConvertToFloat(salesData.stateLowTax),
          cityCountyTax: salesData.cityCountyTax,
          countyTax: salesData.countyTax,
          cityTax: salesData.cityTax,
          cbdTax: salesData.cbdTax,
          liquorTax: salesData.liquorTax,
          beerTax: salesData.beerTax,
          specialTax: salesData.specialTax,
          fees911: removeCommasAndConvertToFloat(salesData.fees911),
          totalSalesTax: salesData.totalSalesTax,
          discount: salesData.discount,
          excessTaxCollected: salesData.excessTaxCollected,
          credit: removeCommasAndConvertToFloat(salesData.credit),
          netSalesTax: salesData.netSalesTax,
          sendEmail: salesData.sendEmail || false,
          temporaryNotes: salesData.temporaryNotes || '',
        };
        try {
          const salesTax: any = await addSales(data);
          if (salesTax.meta.status === 200) {
            // setClientData(client.data);
            // setNewClient(client.data);
            NotificationManager.success(
              'Sales Record added successfully',
              'Success',
              3000
            );
            return salesTax;
          }
        } catch (error) {
          NotificationManager.error(error.message, 'Error', 3000);
          return false;
        }
        
      } else {
        NotificationManager.error('Validation Failed!!', 'Error', 3000);
      }
    } finally {
      setLoading(false); // Stop the loader, whether successful or not
    }
  };
  useEffect(() => {
    // If client object is provided, populate the form fields
    if (sales) {
      setSalesData(sales);
      handleInputChange('client', sales.client);
    }
  }, [sales]);

  const handleInputChange = async (field, value) => {
    const regex = /^[0-9]*(\.[0-9]{0,1})?$/;

    if (field === 'client') {
      const selectedClient = await getClientById(value.id);
      if (selectedClient) {
        setSalesData((prevData) => {
          const sales = {
            ...prevData,
            client: selectedClient.data
          };
          sales.clientStateHighTax = sales.client.salesTaxes
            ? sales.client.salesTaxes.stateHighTax
            : '';
          sales.clientStateLowTax = sales.client.salesTaxes
            ? sales.client.salesTaxes.stateLowTax
            : '';
          sales.clientCityCountyTax = sales.client.salesTaxes
            ? sales.client.salesTaxes.cityCountyTax
            : '';
          sales.clientCountyTax = sales.client.salesTaxes
            ? sales.client.salesTaxes.countyTax
            : '';
          sales.clientCityTax = sales.client.salesTaxes
            ? sales.client.salesTaxes.cityTax
            : '';
          sales.clientCBD = sales.client.salesTaxes
            ? sales.client.salesTaxes.cbdTax
            : '';
          sales.clientSpecial = sales.client.salesTaxes
            ? sales.client.salesTaxes.specialTax
            : '';
          sales.clientLiquor = sales.client.salesTaxes
            ? sales.client.salesTaxes.liquorTax
            : '';
          sales.clientBeer = sales.client.salesTaxes
            ? sales.client.salesTaxes.beerTax
            : '';
          sales.client911 = sales.client.salesTaxes
            ? sales.client.salesTaxes.fees911
            : '';
          sales.clientDiscountRate = sales.client.salesTaxes
            ? sales.client.salesTaxes.discountRate
            : '';
          return sales;
        });
      }
    }

    setSalesData((prevData) => {
      let updatedData: any = {};
      if (
        !(field === 'month' || field === 'year' || field === 'client') &&
        regex.test(value)
      ) {
        updatedData = {
          ...prevData,
          [field]: `${value}`
        };
      } else {
        updatedData = {
          ...prevData,
          [field]: value
        };
      }

      updatedData.totalExempt =
        removeCommasAndConvertToFloat(updatedData.carWash) +
        removeCommasAndConvertToFloat(updatedData.newsPaper) +
        removeCommasAndConvertToFloat(updatedData.ebt) +
        removeCommasAndConvertToFloat(updatedData.exemptFood) +
        removeCommasAndConvertToFloat(updatedData.saleTaxHoliday) +
        removeCommasAndConvertToFloat(updatedData.otherExempt);

      updatedData.totalHighTax =
        removeCommasAndConvertToFloat(updatedData.highTaxGrocery) +
        removeCommasAndConvertToFloat(updatedData.beer) +
        removeCommasAndConvertToFloat(updatedData.cigarettes) +
        removeCommasAndConvertToFloat(updatedData.hotFoodDeli) +
        removeCommasAndConvertToFloat(updatedData.fountainDrink) +
        removeCommasAndConvertToFloat(updatedData.hotCoffee) +
        removeCommasAndConvertToFloat(updatedData.tobacco) +
        removeCommasAndConvertToFloat(updatedData.novelties) +
        removeCommasAndConvertToFloat(updatedData.phoneCards) +
        removeCommasAndConvertToFloat(updatedData.cbdDelta) +
        removeCommasAndConvertToFloat(updatedData.candies) +
        removeCommasAndConvertToFloat(updatedData.wine) +
        removeCommasAndConvertToFloat(updatedData.otherHigh);

      updatedData.totalLowTax =
        removeCommasAndConvertToFloat(updatedData.lowTaxGrocery) +
        removeCommasAndConvertToFloat(updatedData.foodIngredients) +
        removeCommasAndConvertToFloat(updatedData.iceCream) +
        removeCommasAndConvertToFloat(updatedData.bottledWater) +
        removeCommasAndConvertToFloat(updatedData.fruitsVegetables) +
        removeCommasAndConvertToFloat(updatedData.softDrinks) +
        removeCommasAndConvertToFloat(updatedData.coffee) +
        removeCommasAndConvertToFloat(updatedData.chips) +
        removeCommasAndConvertToFloat(updatedData.dairy) +
        removeCommasAndConvertToFloat(updatedData.rawMeet) +
        removeCommasAndConvertToFloat(updatedData.otherLow);

      updatedData.totalSales =
        removeCommasAndConvertToFloat(updatedData.gasolineAmount) +
        removeCommasAndConvertToFloat(updatedData.totalExempt) +
        removeCommasAndConvertToFloat(updatedData.totalHighTax) +
        removeCommasAndConvertToFloat(updatedData.totalLowTax);

      updatedData.sumOfStateHighAndStateLowTax =
        removeCommasAndConvertToFloat(updatedData.totalHighTax) +
        removeCommasAndConvertToFloat(updatedData.totalLowTax);

      updatedData.totalSpecial =
        removeCommasAndConvertToFloat(updatedData.hotFoodDeli) +
        removeCommasAndConvertToFloat(updatedData.beer);

      updatedData.stateHighTax = calculateTax(
        updatedData.totalHighTax,
        updatedData.clientStateHighTax
      );
      updatedData.stateLowTax = calculateTax(
        updatedData.totalLowTax,
        updatedData.clientStateLowTax
      );
      updatedData.cityCountyTax = calculateTax(
        updatedData.sumOfStateHighAndStateLowTax,
        updatedData.clientCityCountyTax
      );
      updatedData.countyTax = calculateTax(
        updatedData.sumOfStateHighAndStateLowTax,
        updatedData.clientCountyTax
      );
      updatedData.cityTax = calculateTax(
        updatedData.sumOfStateHighAndStateLowTax,
        updatedData.clientCityTax
      );
      updatedData.cbdTax = calculateTax(
        updatedData.cbdDelta,
        updatedData.clientCBD
      );
      updatedData.specialTax = calculateTax(
        updatedData.totalSpecial,
        updatedData.clientSpecial
      );
      updatedData.liquorTax = calculateTax(
        updatedData.wine,
        updatedData.clientLiquor
      );
      updatedData.beerTax = calculateTax(
        updatedData.beer,
        updatedData.clientBeer
      );
      updatedData.total911 =
        removeCommasAndConvertToFloat(updatedData.fees911) *
        removeCommasAndConvertToFloat(updatedData.client911);

      updatedData.totalSalesTax =
        removeCommasAndConvertToFloat(updatedData.stateHighTax) +
        removeCommasAndConvertToFloat(updatedData.stateLowTax) +
        removeCommasAndConvertToFloat(updatedData.cityCountyTax) +
        removeCommasAndConvertToFloat(updatedData.countyTax) +
        removeCommasAndConvertToFloat(updatedData.cityTax) +
        removeCommasAndConvertToFloat(updatedData.cbdTax) +
        removeCommasAndConvertToFloat(updatedData.specialTax) +
        removeCommasAndConvertToFloat(updatedData.liquorTax) +
        removeCommasAndConvertToFloat(updatedData.beerTax) +
        removeCommasAndConvertToFloat(updatedData.total911);
      if (updatedData.client && updatedData.client.state === 'ARKANSAS') {
      }
      updatedData.discount =
        updatedData.client.state === 'ARKANSAS'
          ? calculateTax(
              removeCommasAndConvertToFloat(updatedData.stateHighTax) +
                removeCommasAndConvertToFloat(updatedData.stateLowTax) +
                removeCommasAndConvertToFloat(updatedData.cityCountyTax) +
                removeCommasAndConvertToFloat(updatedData.countyTax) +
                removeCommasAndConvertToFloat(updatedData.cityTax) +
                removeCommasAndConvertToFloat(updatedData.total911),
              removeCommasAndConvertToFloat(updatedData.clientDiscountRate)
            )
          : calculateTax(
              updatedData.totalSalesTax,
              updatedData.clientDiscountRate
            );

      if (
        updatedData.client &&
        updatedData.client.state === 'MISSISSIPPI' &&
        updatedData.discount > 50
      ) {
        updatedData.discount = 50;
      }

      updatedData.netSalesTax =
        removeCommasAndConvertToFloat(updatedData.totalSalesTax) +
        removeCommasAndConvertToFloat(updatedData.excessTaxCollected) -
        removeCommasAndConvertToFloat(updatedData.discount) -
        removeCommasAndConvertToFloat(updatedData.credit);
      return updatedData;
    });
    return true;
  };

  const validateFields = async () => {
    let errors = {};
    const basicDataErrors = await validateSalesFormDate(salesData);
    errors = { ...errors, ...basicDataErrors };
    await setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };
  useEffect(() => {
    const fetchInitialData = async () => {
      await getClients();
    };

    // Call the fetch function
    fetchInitialData();
  }, []);

  useEffect(() => {}, [salesData]);
  useEffect(() => {
    getPreviousSalesData({
      year: salesData.year,
      month: salesData.month,
      clientId: salesData.client && salesData.client.id
    });
  }, [salesData.year, salesData.month, salesData.client]);

  const resetSalesData = () => {
    setSalesData({
      client: '',
      year: previousYear,
      month: previousMonth,
      clientId: '',
      gasolineGallon: '',
      gasolineAmount: '',
      carWash: '',
      newsPaper: '',
      ebt: '',
      exemptFood: '',
      saleTaxHoliday: '',
      otherExempt: '',
      totalExempt: 0,
      highTaxGrocery: '',
      beer: '',
      cigarettes: '',
      hotFoodDeli: '',
      fountainDrink: '',
      hotCoffee: '',
      tobacco: '',
      novelties: '',
      phoneCards: '',
      cbdDelta: '',
      candies: '',
      wine: '',
      otherHigh: '',
      totalHighTax: 0,
      lowTaxGrocery: '',
      foodIngredients: '',
      iceCream: '',
      bottledWater: '',
      fruitsVegetables: '',
      softDrinks: '',
      coffee: '',
      chips: '',
      dairy: '',
      rawMeet: '',
      otherLow: '',
      totalLowTax: 0,
      totalSpecial: 0,
      fees911: '',
      clientStateHighTax: '',
      clientStateLowTax: '',
      clientCityCountyTax: '',
      clientCountyTax: '',
      clientCityTax: '',
      clientCBD: '',
      clientSpecial: '',
      clientLiquor: '',
      clientBeer: '',
      client911: '',
      clientDiscountRate: '',
      sumOfStateHighAndStateLowTax: '',
      totalSales: '',
      excessTaxCollected: '',
      credit: '',
      stateHighTax: '',
      stateLowTax: '',
      cityCountyTax: '',
      countyTax: '',
      cityTax: '',
      cbdTax: '',
      liquorTax: '',
      beerTax: '',
      total911: '',
      specialTax: '',
      totalSalesTax: '',
      discount: '',
      netSalesTax: '',
      sendEmail: false,
      temporaryNotes: ''
    });
  };

const handleUnApprove = async (e) => {
  handleInputChange('sendEmail', false);
  await handleSubmit(e);
};

    
  const resetClose = () => {
    resetSalesData();
    onClose();
  };

  const openViewSalesModal = () => {
    setIsViewSalesModalOpen(true);
  };
  const closeViewSalesModal = () => {
    setIsViewSalesModalOpen(false);
  };

  const handleView = async () => {
    console.log(salesData);
    setViewSale(salesData);
    setIsViewSalesModalOpen(true);
  };




  const user = JSON.parse(localStorage.getItem('user') || 'null');

  return (
    <>
      {loading && (
        <div
          className="loader-wrapper"
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 9999
          }}
        >
          <BarLoader
            color={'#19d2d2'}
            loading={loading}
            style={{ width: 'inherit' }}
          />
        </div>
      )}

      <Modal
        open={open}
        onClose={onClose}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backdropFilter: 'blur(8px)', // Adjust blur intensity as needed
          WebkitBackdropFilter: 'blur(8px)'
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '5%',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '90%',
            height: '85%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: '10px',

            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              display: 'none' // Hide scrollbar for Chrome/Safari
            },
            msOverflowStyle: 'none', // Hide scrollbar for IE and Edge
            scrollbarWidth: 'none'
          }}
        >
          <CloseIcon
            sx={{
              position: 'fixed',
              top: 10,
              right: 10,
              cursor: 'pointer',
              zIndex: 2
            }}
            onClick={resetClose}
          />

          <Typography
            variant="h3"
            component="div"
            sx={{ marginBottom: 2, color: '#1976D2' }}
          >
            {action === 'add'
              ? 'Add Sales'
              : salesData.client.legalBusinessName}
          </Typography>

          {loading && (
            <div className="loader-wrapper" style={{ textAlign: 'center' }}>
              <BarLoader
                color={'#1976D2'}
                loading={loading}
                width={'inherit'}
              />
            </div>
          )}
          {/* <Box
            sx={{
              marginBottom: 2,
              textAlign: 'right',
              color: !!salesData.emailSent ? 'green' : 'red'
            }}
          >
            <h4>{salesData.emailSent ? 'Approved' : 'Unapproved'}</h4>
          </Box> */}
          <Box display={'flex'} justifyContent={'flex-end'}>
            <Box
              component="div" // Set the component to "div" for non-clickable behavior
              sx={{
                marginBottom: 2,
                textAlign: 'right',
                color: salesData.emailSent ? 'green' : 'red',
                border: '1px solid', // Add border to mimic the outline effect
                borderColor: salesData.emailSent ? 'green' : 'red', // Match border color with text color
                borderRadius: '4px', // Add border radius for rounded corners
                padding: '8px 16px', // Add padding for spacing
                width: 'fit-content'
              }}
            >
              {salesData.emailSent ? 'Approved' : 'Unapproved'}
              {console.log(salesData.emailSent)}
            </Box>
            {user.role === 'admin' && salesData.emailSent && (
            // <Button
            //   variant="contained"
            //   color="error"
            //   size="medium"
            //   onClick={handleUnapprove}
            //   sx={{ fontSize: '1rem' }}
            // >
            //   Unapprove
            // </Button>
            <Box sx={{ ml: 2 }}>
              <Button
                        variant="contained"
                        color="error"
                        disabled={loading}
                        onClick={handleUnApprove}
                        size="medium"
                        // sx={{ fontSize: '1rem' }}
                      >
                        Unapprove{' '}
                      </Button>
            </Box>
          )}
          </Box>


          <form onSubmit={handleSubmit}>
            <fieldset className='border-none border-0' disabled={salesData.emailSent}>
            {console.log(salesData.emailSent)}
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <InputLabel htmlFor="client">Client</InputLabel>
                <Autocomplete
                  id="client"
                  options={clients}
                  getOptionLabel={(client) => client.legalBusinessName || ''}
                  value={salesData.client}
                  onChange={(_, newValue) =>
                    handleInputChange('client', newValue)
                  }
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" size="small" />
                  )}
                />
              </Grid>
              <Dropdown
                value={salesData.year}
                label="Year"
                placeholder="Select Year"
                handleInputChange={handleInputChange}
                validationField={validationErrors.year}
                name="year"
                options={yearsArray.map(String)}
              />
              <Dropdown
                value={salesData.month}
                label="Month"
                placeholder="Select month"
                handleInputChange={handleInputChange}
                validationField={validationErrors.month}
                name="month"
                options={monthArray.map(String)}
              />
              <TextInput
                value={
                  salesData.client ? formatClientId(+salesData.client.id) : ''
                }
                label="Client Id"
                placeholder=""
                handleInputChange={handleInputChange}
                validationField={validationErrors.clientId}
                name="Client Id"
                props={{
                  readOnly: false,
                  style: { backgroundColor: '#f5f5f5' }
                }}
              />
              <TextInput
                value={salesData.client ? salesData.client.federalTaxId : ''}
                label="Federal Tax Id"
                placeholder=""
                handleInputChange={handleInputChange}
                validationField={validationErrors.federalTaxId}
                name="Federal Tax Id"
                props={{
                  readOnly: true,
                  style: { backgroundColor: '#f5f5f5' }
                }}
              />
              {/* <TextInput
                value={
                  salesData.client ? salesData.client.legalBusinessName : ''
                }
                label="Legal Business Name"
                placeholder=""
                handleInputChange={handleInputChange}
                validationField={validationErrors.legalBusinessName}
                name="Legal Business Name"
                props={{ readOnly: true }}
              /> */}
              <TextInput
                value={salesData.client ? salesData.client.doingBusinessAs : ''}
                label="Doing Business As"
                placeholder=""
                handleInputChange={handleInputChange}
                validationField={validationErrors.doingBusinessAs}
                name="Doing Business As"
                props={{
                  readOnly: true,
                  style: { backgroundColor: '#f5f5f5' }
                }}
              />
              <TextInput
                value={
                  salesData.client
                    ? salesData.client.salesTaxes &&
                      salesData.client.salesTaxes.salesTaxId
                    : ''
                }
                label="Sales Tax Id"
                placeholder=""
                handleInputChange={handleInputChange}
                validationField={validationErrors.salesTaxId}
                name="Sales Tax Id"
                props={{
                  readOnly: true,
                  style: { backgroundColor: '#f5f5f5' }
                }}
              />
              <Grid container xs={12}></Grid>
              <TextInput
                value={salesData.client ? salesData.client.state : ''}
                label="State"
                placeholder=""
                handleInputChange={handleInputChange}
                validationField={validationErrors.state}
                name="State"
                props={{
                  readOnly: true,
                  style: { backgroundColor: '#f5f5f5' }
                }}
              />
              <TextInput
                value={salesData.client ? salesData.client.county : ''}
                label="County"
                placeholder=""
                handleInputChange={handleInputChange}
                validationField={validationErrors.county}
                name="County"
                props={{
                  readOnly: true,
                  style: { backgroundColor: '#f5f5f5' }
                }}
              />
              <TextInput
                value={salesData.client ? salesData.client.city : ''}
                label="City"
                placeholder=""
                handleInputChange={handleInputChange}
                validationField={validationErrors.city}
                name="City"
                props={{
                  readOnly: true,
                  style: { backgroundColor: '#f5f5f5' }
                }}
              />
              <FormHeading text="" />
              <Grid item xs={12} md={6}>
                <InputLabel htmlFor="importantNotes">
                  Permanent Notes
                </InputLabel>
                <TextField
                  id="importantNotes"
                  variant="outlined"
                  // disabled
                  multiline
                  rows={5}
                  InputProps={{
                    readOnly: true, // Make it read-only instead of disabled
                    style: { color: 'red' }, // Add red text color
                  }}
                  fullWidth
                  size="small"
                  value={
                    salesData.client
                      ? salesData.client.salesTaxes &&
                        salesData.client.salesTaxes.importantNotes
                      : ''
                  }
                  error={!!validationErrors.importantNotes}
                  helperText={validationErrors.importantNotes}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputLabel htmlFor="temporaryNotes">
                  Temporary Notes
                </InputLabel>
                <TextField
                  id="temporaryNotes"
                  variant="outlined"
                  multiline
                  rows={5}
                  InputProps={{
                    style: { color: 'red' }, // Adds red text color
                  }}
                  fullWidth
                  size="small"
                  value={
                    salesData.temporaryNotes ? salesData.temporaryNotes : ''
                  }
                  error={!!validationErrors.temporaryNotes}
                  helperText={validationErrors.temporaryNotes}
                  onChange={(e) => {
                    handleInputChange('temporaryNotes', e.target.value);
                  }}
                />
              </Grid>
              <FormHeading text="" />

              <SalesDoubleInput
                label="Gasoline Gallon"
                value1={salesData.gasolineGallon}
                handleInputChange={handleInputChange}
                value2={previousMonthSale && previousMonthSale.gasolineGallon}
                validationField={validationErrors.gasolineGallon}
                name="gasolineGallon"
              />

              <SalesDoubleInput
                label="Gasoline Amount"
                value1={salesData.gasolineAmount}
                handleInputChange={handleInputChange}
                value2={previousMonthSale && previousMonthSale.gasolineAmount}
                name="gasolineAmount"
                validationField={validationErrors.gasolineAmount}
              />

              <FormHeading
                text="Tax-Exempt Sales"
                color="#d27519"
                center={true}
              />

              <SalesDoubleInput
                label="Car Wash"
                value1={salesData.carWash}
                value2={previousMonthSale && previousMonthSale.carWash}
                handleInputChange={handleInputChange}
                name="carWash"
                validationField={validationErrors.carWash}
              />
              <SalesDoubleInput
                label="Newspapers"
                value1={salesData.newsPaper}
                value2={previousMonthSale && previousMonthSale.newsPaper}
                handleInputChange={handleInputChange}
                name="newsPaper"
                validationField={validationErrors.newsPaper}
              />
              <SalesDoubleInput
                label="EBT"
                value1={salesData.ebt}
                value2={previousMonthSale && previousMonthSale.ebt}
                name="ebt"
                handleInputChange={handleInputChange}
                validationField={validationErrors.ebt}
              />
              <SalesDoubleInput
                label="Exempt Food"
                value1={salesData.exemptFood}
                value2={previousMonthSale && previousMonthSale.exemptFood}
                name="exemptFood"
                handleInputChange={handleInputChange}
                validationField={validationErrors.exemptFood}
              />
              <SalesDoubleInput
                label="Sale Tax Holiday"
                value1={salesData.saleTaxHoliday}
                value2={previousMonthSale && previousMonthSale.saleTaxHoliday}
                name="saleTaxHoliday"
                handleInputChange={handleInputChange}
                validationField={validationErrors.saleTaxHoliday}
              />
              <SalesDoubleInput
                label="Other Exempt"
                value1={salesData.otherExempt}
                value2={previousMonthSale && previousMonthSale.otherExempt}
                name="otherExempt"
                handleInputChange={handleInputChange}
                validationField={validationErrors.otherExempt}
              />

              <Total
                label="Total Exempt:"
                value1={Number(salesData.totalExempt).toFixed(0)}
                value2={previousMonthSale && previousMonthSale.totalExempt}
              />

              <FormHeading
                text="High Tax Sales"
                color="#d27519"
                center={true}
              />

              <SalesDoubleInput
                label="High Tax Grocery"
                value1={salesData.highTaxGrocery}
                value2={previousMonthSale && previousMonthSale.highTaxGrocery}
                name="highTaxGrocery"
                handleInputChange={handleInputChange}
                validationField={validationErrors.highTaxGrocery}
              />
              <SalesDoubleInput
                label="Beer"
                value1={salesData.beer}
                value2={previousMonthSale && previousMonthSale.beer}
                name="beer"
                handleInputChange={handleInputChange}
                validationField={validationErrors.beer}
                
              />
              <SalesDoubleInput
                label="Cigarettes"
                value1={salesData.cigarettes}
                value2={previousMonthSale && previousMonthSale.cigarettes}
                name="cigarettes"
                handleInputChange={handleInputChange}
                validationField={validationErrors.cigarettes}
              />
              <SalesDoubleInput
                label="Hot Food (Deli)"
                value1={salesData.hotFoodDeli}
                value2={previousMonthSale && previousMonthSale.hotFoodDeli}
                name="hotFoodDeli"
                handleInputChange={handleInputChange}
                validationField={validationErrors.hotFoodDeli}
              />
              <SalesDoubleInput
                label="Fountain Drink"
                value1={salesData.fountainDrink}
                value2={previousMonthSale && previousMonthSale.fountainDrink}
                name="fountainDrink"
                handleInputChange={handleInputChange}
                validationField={validationErrors.fountainDrink}
              />
              <SalesDoubleInput
                label="Hot Coffee"
                value1={salesData.hotCoffee}
                name="hotCoffee"
                value2={previousMonthSale && previousMonthSale.hotCoffee}
                handleInputChange={handleInputChange}
                validationField={validationErrors.hotCoffee}
              />
              <SalesDoubleInput
                label="Tobacco"
                value1={salesData.tobacco}
                name="tobacco"
                value2={previousMonthSale && previousMonthSale.tobacco}
                handleInputChange={handleInputChange}
                validationField={validationErrors.tobacco}
              />
              <SalesDoubleInput
                label="Novelties"
                value1={salesData.novelties}
                name="novelties"
                value2={previousMonthSale && previousMonthSale.novelties}
                handleInputChange={handleInputChange}
                validationField={validationErrors.novelties}
              />
              <SalesDoubleInput
                label="Phone Cards"
                value1={salesData.phoneCards}
                name="phoneCards"
                value2={previousMonthSale && previousMonthSale.phoneCards}
                handleInputChange={handleInputChange}
                validationField={validationErrors.phoneCards}
              />
              <SalesDoubleInput
                label="CBD (Delta -8 & -10)"
                value1={salesData.cbdDelta}
                name="cbdDelta"
                value2={previousMonthSale && previousMonthSale.cbdDelta}
                handleInputChange={handleInputChange}
                validationField={validationErrors.cbdDelta}
              />
              <SalesDoubleInput
                label="Candies"
                value1={salesData.candies}
                name="candies"
                value2={previousMonthSale && previousMonthSale.candies}
                handleInputChange={handleInputChange}
                validationField={validationErrors.candies}
              />
              {salesData.client && salesData.client.state !== 'TENNESSEE' && (
                <SalesDoubleInput
                  label="Wine"
                  value1={salesData.wine}
                  name="wine"
                  value2={previousMonthSale && previousMonthSale.wine}
                  handleInputChange={handleInputChange}
                  validationField={validationErrors.wine}
                />
              )}

              <SalesDoubleInput
                label="Other High"
                value1={salesData.otherHigh}
                name="otherHigh"
                value2={previousMonthSale && previousMonthSale.otherHigh}
                handleInputChange={handleInputChange}
                validationField={validationErrors.otherHigh}
              />
              <Total
                label="Total High Tax Sales:"
                value1={Number(salesData.totalHighTax).toFixed(0)}
                value2={previousMonthSale && previousMonthSale.totalHighTax}
              />
              <FormHeading text="Low Tax Sales" color="#d27519" center={true} />
              <SalesDoubleInput
                label="Low Tax Grocery"
                value1={salesData.lowTaxGrocery}
                name="lowTaxGrocery"
                value2={previousMonthSale && previousMonthSale.lowTaxGrocery}
                handleInputChange={handleInputChange}
                validationField={validationErrors.lowTaxGrocery}
              />
              <SalesDoubleInput
                label="Food Ingredients"
                value1={salesData.foodIngredients}
                name="foodIngredients"
                value2={previousMonthSale && previousMonthSale.foodIngredients}
                handleInputChange={handleInputChange}
                validationField={validationErrors.foodIngredients}
              />
              <SalesDoubleInput
                label="Ice Cream"
                value1={salesData.iceCream}
                name="iceCream"
                value2={previousMonthSale && previousMonthSale.iceCream}
                handleInputChange={handleInputChange}
                validationField={validationErrors.iceCream}
              />
              <SalesDoubleInput
                label="Bottled Water"
                value1={salesData.bottledWater}
                name="bottledWater"
                value2={previousMonthSale && previousMonthSale.bottledWater}
                handleInputChange={handleInputChange}
                validationField={validationErrors.bottledWater}
              />
              <SalesDoubleInput
                label="Fruits/Vegetables"
                value1={salesData.fruitsVegetables}
                name="fruitsVegetables"
                value2={previousMonthSale && previousMonthSale.fruitsVegetables}
                handleInputChange={handleInputChange}
                validationField={validationErrors.fruitsVegetables}
              />
              <SalesDoubleInput
                label="Soft Drinks"
                value1={salesData.softDrinks}
                name="softDrinks"
                value2={previousMonthSale && previousMonthSale.softDrinks}
                handleInputChange={handleInputChange}
                validationField={validationErrors.softDrinks}
              />
              <SalesDoubleInput
                label="Coffee"
                value1={salesData.coffee}
                name="coffee"
                value2={previousMonthSale && previousMonthSale.coffee}
                handleInputChange={handleInputChange}
                validationField={validationErrors.coffee}
              />
              <SalesDoubleInput
                label="Chips"
                value1={salesData.chips}
                name="chips"
                value2={previousMonthSale && previousMonthSale.chips}
                handleInputChange={handleInputChange}
                validationField={validationErrors.chips}
              />
              <SalesDoubleInput
                label="Dairy"
                value1={salesData.dairy}
                name="dairy"
                value2={previousMonthSale && previousMonthSale.dairy}
                handleInputChange={handleInputChange}
                validationField={validationErrors.dairy}
              />
              <SalesDoubleInput
                label="Raw Meat"
                value1={salesData.rawMeet}
                name="rawMeet"
                value2={previousMonthSale && previousMonthSale.rawMeet}
                handleInputChange={handleInputChange}
                validationField={validationErrors.rawMeet}
              />
              <SalesDoubleInput
                label="Other Low"
                value1={salesData.otherLow}
                name="otherLow"
                value2={previousMonthSale && previousMonthSale.otherLow}
                handleInputChange={handleInputChange}
                validationField={validationErrors.otherLow}
              />
              <Total
                label="Total Low Tax Sales:"
                value1={Number(salesData.totalLowTax).toFixed(0)}
                value2={previousMonthSale && previousMonthSale.totalLowTax}
              />
              <Total
                label="Total Sales:"
                value1={Number(salesData.totalSales).toFixed(0)}
                value2={previousMonthSale && previousMonthSale.totalSales}
              />
              <TripleInput
                label="State High Tax"
                value1={Number(salesData.totalHighTax).toFixed(3)}
                value2={Number(salesData.clientStateHighTax).toFixed(3)}
                value3={Number(salesData.stateHighTax).toFixed(3)}
                handleInputChange={handleInputChange}
              />
              <TripleInput
                label="State Low Tax"
                value1={Number(salesData.totalLowTax).toFixed(3)}
                value2={Number(salesData.clientStateLowTax).toFixed(3)}
                value3={Number(salesData.stateLowTax).toFixed(3)}
                handleInputChange={handleInputChange}
              />
              <TripleInput
                label="City/County Taxes"
                value1={Number(salesData.sumOfStateHighAndStateLowTax).toFixed(
                  3
                )}
                value2={Number(salesData.clientCityCountyTax).toFixed(3)}
                value3={Number(salesData.cityCountyTax).toFixed(3)}
                handleInputChange={handleInputChange}
              />
              <TripleInput
                label="County Tax"
                value1={Number(salesData.sumOfStateHighAndStateLowTax).toFixed(
                  3
                )}
                value2={Number(salesData.clientCountyTax).toFixed(3)}
                value3={Number(salesData.countyTax).toFixed(3)}
                handleInputChange={handleInputChange}
              />
              <TripleInput
                label="City Tax"
                value1={Number(salesData.sumOfStateHighAndStateLowTax).toFixed(
                  3
                )}
                value2={Number(salesData.clientCityTax).toFixed(3)}
                value3={Number(salesData.cityTax).toFixed(3)}
                handleInputChange={handleInputChange}
              />
              <TripleInput
                label="CBD Tax"
                value1={Number(salesData.cbdDelta).toFixed(3)}
                value2={Number(salesData.clientCBD).toFixed(3)}
                value3={Number(salesData.cbdTax).toFixed(3)}
                handleInputChange={handleInputChange}
              />
              {salesData.client && salesData.client.state === 'MISSISSIPPI' && (
                <TripleInput
                  label="Special Tax"
                  value1={Number(salesData.totalSpecial).toFixed(3)}
                  value2={Number(salesData.clientSpecial).toFixed(3)}
                  value3={Number(salesData.specialTax).toFixed(3)}
                  handleInputChange={handleInputChange}
                />
              )}
              {salesData.client && salesData.client.state === 'ARKANSAS' && (
                <TripleInput
                  label="Liquor Tax"
                  value1={Number(salesData.wine).toFixed(3)}
                  value2={Number(salesData.clientLiquor).toFixed(3)}
                  value3={Number(salesData.liquorTax).toFixed(3)}
                  handleInputChange={handleInputChange}
                />
              )}
              {salesData.client && salesData.client.state === 'ARKANSAS' && (
                <TripleInput
                  label="Beer Tax"
                  handleInputChange={handleInputChange}
                  value1={Number(salesData.beer).toFixed(3)}
                  value2={Number(salesData.clientBeer).toFixed(3)}
                  value3={Number(salesData.beerTax).toFixed(3)}
                />
              )}
              <TripleInput
                label="911 Fees"
                value1={salesData.fees911}
                name="fees911"
                handleInputChange={handleInputChange}
                value2={Number(salesData.client911).toFixed(3)}
                value3={Number(salesData.total911).toFixed(3)}
              />
              <FormHeading text="" />
              <ThirdValueDisplay
                label="Total Sales Tax"
                value={Number(salesData.totalSalesTax).toFixed(3)}
                value2={Number(
                  (previousMonthSale && previousMonthSale.totalSalesTax) || 0
                ).toFixed(0)}
                name="total"
                handleInputChange={handleInputChange}
              />
              <TripleInput
                label="Discount "
                value1={
                  salesData.client && salesData.client.state == 'ARKANSAS'
                    ? Number(
                        removeCommasAndConvertToFloat(salesData.stateHighTax) +
                          removeCommasAndConvertToFloat(salesData.stateLowTax) +
                          removeCommasAndConvertToFloat(
                            salesData.cityCountyTax
                          ) +
                          removeCommasAndConvertToFloat(salesData.countyTax) +
                          removeCommasAndConvertToFloat(salesData.cityTax) +
                          removeCommasAndConvertToFloat(salesData.total911)
                      ).toFixed(3)
                    : Number(salesData.totalSalesTax).toFixed(3)
                }
                value2={Number(salesData.clientDiscountRate).toFixed(3)}
                value3={Number(salesData.discount).toFixed(3)}
                handleInputChange={handleInputChange}
              />
              <ThirdValueDisplay
                label="Excess Tax Collected"
                value={salesData.excessTaxCollected}
                name="excessTaxCollected"
                handleInputChange={handleInputChange}
              />
              <ThirdValueDisplay
                label="Credit"
                value={salesData.credit}
                name="credit"
                handleInputChange={handleInputChange}
              />
              <ThirdValueDisplay
                label="Net Sales Tax"
                value={Number(salesData.netSalesTax).toFixed(0)}
                value2={Number(
                  (previousMonthSale && previousMonthSale.netSalesTax) || 0
                ).toFixed(0)}
                name="netSalesTax"
                handleInputChange={handleInputChange}
              />

              <FormHeading text="" color="#d27519" center={true} />
              <Grid container spacing={4} sx={{ mx: 4 }}>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={4}>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={resetClose}
                        size="medium"
                        sx={{ fontSize: '1rem' }}
                      >
                        Close
                      </Button>
                    </Grid>
                    <Grid item xs={4}>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        onClick={handleSubmit}
                        size="medium"
                        sx={{ fontSize: '1rem' }}
                      >
                        Save{' '}
                        <SaveOutlinedIcon
                          sx={{ ml: '4px' }}
                          fontSize="medium"
                        />
                      </Button>
                    </Grid>
                    <Grid item xs={4}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="medium"
                        sx={{ fontSize: '1rem' }}
                        onClick={() => handleView()}
                      >
                        Preview{' '}
                        <GradingIcon sx={{ ml: '4px' }} fontSize="medium" />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={saveAndEmail}
                        size="medium"
                        sx={{ fontSize: '1rem' }}
                        disabled={loading}
                      >
                        Approve & Email{' '}
                        <EmailIcon sx={{ ml: '4px' }} fontSize="medium" />
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="medium"
                        sx={{ fontSize: '1rem' }}
                      >
                        Export{' '}
                        <InsertDriveFileIcon
                          sx={{ ml: '4px' }}
                          fontSize="medium"
                        />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            </fieldset>
          </form>
          <ViewSales
            open={isViewSalesModalOpen}
            onClose={closeViewSalesModal}
            viewSales={viewSale}
          />
        </Box>
      </Modal>
    </>
  );
};

export default SalesForm;

const Total = ({ label, value1, value2 = '' }) => {
  const formattedValue1 = formatNumberWithCommas(value1);

  return (
    <Grid item xs={12} md={12}>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} sx={{ xs: { mt: 0 } }}>
            <Typography sx={{ color: '#black', mt: 1, fontWeight: '550' }}>
              {label}{' '}
            </Typography>
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              label=""
              value={formattedValue1}
              variant="outlined"
              size="small"
              InputProps={{
                readOnly: true,
                style: { color: '#d27519', fontWeight: '600' }
              }}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              label=""
              variant="outlined"
              size="small"
              InputProps={{
                readOnly: true,
                style: { fontWeight: '600', backgroundColor: '#f5f5f5' }
              }}
              id=""
              placeholder=""
              fullWidth
              value={value2 && Number(value2).toFixed(3)}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export { Total };

const TripleInput = ({
  label,
  value1,
  value2,
  value3,
  name = '',
  handleInputChange
}) => (
  <>
    <Grid item xs={12} md={8}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3} sx={{ xs: { mt: 0 } }}>
          <InputLabel sx={{ mt: 1 }}>{label} </InputLabel>
        </Grid>
        <Grid item xs={4} md={3}>
          <TextField
            label=""
            value={value1}
            variant="outlined"
            size="small"
            onChange={(e) => handleInputChange(name, e.target.value)}
            InputProps={{
              style: { backgroundColor: label !== '911 Fees' && '#f5f5f5' },
              readOnly: label === '911 Fees' ? false : true,
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={4} md={3}>
          <TextField
            label=""
            value={value2}
            variant="outlined"
            size="small"
            InputProps={{
              style: { backgroundColor: '#f5f5f5' },
              readOnly: true,
              startAdornment:
                label === '911 Fees' ? (
                  <InputAdornment position="start">$</InputAdornment>
                ) : null,
              endAdornment:
                label !== '911 Fees' ? (
                  <InputAdornment position="end">%</InputAdornment>
                ) : null
            }}
          />
        </Grid>
        <Grid item xs={4} md={3}>
          <TextField
            label=""
            value={value3}
            variant="outlined"
            size="small"
            InputProps={{
              style: { backgroundColor: '#f5f5f5' },
              readOnly: true,
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              )
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  </>
);

const ThirdValueDisplay = ({
  label,
  value,
  value2 = '',
  handleInputChange,
  name = ''
}) => (
  <>
    <Grid item xs={12} md={8}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3} sx={{ xs: { mt: 0 } }}>
          <InputLabel sx={{ mt: 1 }}>{label}</InputLabel>
        </Grid>
        <Grid item xs={6} md={3}></Grid>
        <Grid item xs={6} md={3}>
          {(name === 'total' || name === 'netSalesTax') && (
            <TextField
              label=""
              value={value2}
              variant="outlined"
              size="small"
              onChange={(e) => handleInputChange(name, e.target.value)}
              InputProps={{
                readOnly: name === 'total' || name === 'netSalesTax',
                style: {
                  backgroundColor:
                    name === 'total' || name === 'netSalesTax'
                      ? '#F9E8C9'
                      : 'inherit'
                },
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                )
              }}
            />
          )}
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            label=""
            value={value}
            variant="outlined"
            size="small"
            onChange={(e) => handleInputChange(name, e.target.value)}
            InputProps={{
              readOnly: name === 'total' || name === 'netSalesTax',
              style: {
                backgroundColor:
                  name === 'total' || name === 'netSalesTax'
                    ? '#f5f5f5'
                    : 'inherit'
              },
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              )
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  </>
);

const getYears = (startYear, endYear) => {
  const years = [];
  for (let year = startYear; year >= endYear; year--) {
    years.push(year);
  }
  return years;
};
const getMonths = () => {
  const months = [];
  const startDate = new Date(Date.parse('01 January 2000')); // Start from January
  const endDate = new Date(Date.parse('01 January 1999')); // End before January (arbitrary date)
  while (startDate > endDate) {
    const monthName = startDate.toLocaleString('default', { month: 'long' });
    months.push(monthName);
    startDate.setMonth(startDate.getMonth() - 1);
  }
  return months.reverse(); // Reverse the array to display January to December
};

const calculateTax: any = (amount, taxRate) => {
  return (
    (removeCommasAndConvertToFloat(amount) *
      removeCommasAndConvertToFloat(taxRate)) /
    100
  );
};

const removeCommasAndConvertToFloat: any = (value: any) => {
  if (value !== null && value !== undefined && value !== '') {
    // Remove commas and ensure a valid format for floating-point numbers
    const formattedValue = value
      .toString()
      .replace(/,/g, '')
      .replace(/[^\d.]/g, '');

    // Convert to float
    const floatValue = parseFloat(formattedValue);

    if (!isNaN(floatValue)) {
      return floatValue;
    }
  }

  return 0; // or any default value you want to return for invalid inputs
}