import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const ViewPayroll = ({ open, onClose, payroll }) => {
  function handleClose(): void {
    onClose();
  }
  const [payrollData, setPayrollData] = useState<any>([]);

  useEffect(() => {
    if (payroll) {
      setPayrollData(payroll);
    }
  });
  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          // Other styles...
          backdropFilter: 'blur(8px)', // Adjust blur intensity as needed
          WebkitBackdropFilter: 'blur(8px)' // For Safari support
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%', // Adjust this percentage as needed
            height: '85%', // Adjust this percentage as needed
            bgcolor: 'background.paper',
            borderRadius: 4,
            boxShadow: 24,
            p: 4,
            overflowY: 'auto',
            // '&::-webkit-scrollbar': {
            //   display: 'none' // Hide scrollbar for Chrome/Safari
            // },
            // msOverflowStyle: 'none', // Hide scrollbar for IE and Edge
            // scrollbarWidth: 'none'
          }}
        >
          <CloseIcon
            sx={{
              position: 'sticky',
              top: -25,
              left: '100%',
              right: -10,
              cursor: 'pointer',
              zIndex: 2,
              color: 'red'
            }}
            onClick={() => handleClose()}
          />
          <>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <TableContainer
                    sx={{ overflowX: 'scroll', overflowY: 'auto', zIndex: 1 }}
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{ fontWeight: 'bold', color: '#1976D2' }}
                          >
                            Employee Name
                          </TableCell>
                          <TableCell
                            sx={{ fontWeight: 'bold', color: '#1976D2' }}
                          >
                            Federal Tax ID
                          </TableCell>
                          <TableCell
                            sx={{ fontWeight: 'bold', color: '#1976D2' }}
                          >
                            Gross Wages
                          </TableCell>
                          <TableCell
                            sx={{ fontWeight: 'bold', color: '#1976D2' }}
                          >
                            Social Security
                          </TableCell>
                          <TableCell
                            sx={{ fontWeight: 'bold', color: '#1976D2' }}
                          >
                            Medicare
                          </TableCell>
                          <TableCell
                            sx={{ fontWeight: 'bold', color: '#1976D2' }}
                          >
                            Federal WH
                          </TableCell>
                          <TableCell
                            sx={{ fontWeight: 'bold', color: '#1976D2' }}
                          >
                            State WH
                          </TableCell>
                          <TableCell
                            sx={{ fontWeight: 'bold', color: '#1976D2' }}
                          >
                            Local WH
                          </TableCell>
                          <TableCell
                            sx={{ fontWeight: 'bold', color: '#1976D2' }}
                          >
                            Tips
                          </TableCell>
                          <TableCell
                            sx={{ fontWeight: 'bold', color: '#1976D2' }}
                          >
                            Benefit & Deduction
                          </TableCell>
                          <TableCell
                            sx={{ fontWeight: 'bold', color: '#1976D2' }}
                          >
                            Net Pay
                          </TableCell>
                          <TableCell
                            sx={{ fontWeight: 'bold', color: '#1976D2' }}
                          >
                            FUTA Taxable Wages
                          </TableCell>
                          <TableCell
                            sx={{ fontWeight: 'bold', color: '#1976D2' }}
                          >
                            FUTA Tax
                          </TableCell>
                          <TableCell
                            sx={{ fontWeight: 'bold', color: '#1976D2' }}
                          >
                            FUTA Credit Reduction
                          </TableCell>
                          <TableCell
                            sx={{ fontWeight: 'bold', color: '#1976D2' }}
                          >
                            SUTA Taxable Wages
                          </TableCell>
                          <TableCell
                            sx={{ fontWeight: 'bold', color: '#1976D2' }}
                          >
                            SUTA Tax
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {payrollData.map((row, rowIndex) => (
                          <TableRow key={rowIndex}>
                            <TableCell>{row.employeeName}</TableCell>
                            <TableCell>{row.federalTaxId}</TableCell>
                            <TableCell>{row.grossWages}</TableCell>
                            <TableCell>{row.socSec}</TableCell>
                            <TableCell>{row.medicare}</TableCell>
                            <TableCell>{row.federalWH}</TableCell>
                            <TableCell>{row.stateWH}</TableCell>
                            <TableCell>{row.localWH}</TableCell>
                            <TableCell>{row.tips}</TableCell>
                            <TableCell>{row.benefitDeduction}</TableCell>
                            <TableCell>{row.netPay}</TableCell>
                            <TableCell>{row.futaTaxable}</TableCell>
                            <TableCell>{row.futaTax}</TableCell>
                            <TableCell>{row.futaCredit}</TableCell>
                            <TableCell>{row.sutaTaxable}</TableCell>
                            <TableCell>{row.sutaTax}</TableCell>
                          </TableRow>
                        ))}
                           <TableRow  >
                              <TableCell sx={{fontWeight:800}}>Total</TableCell>
                              <TableCell>{}</TableCell>
                              <TableCell sx={{fontWeight:700}}>{payrollData.reduce((total, entry) => total + +entry.grossWages, 0).toFixed(2)}</TableCell>
                              <TableCell sx={{fontWeight:700}}>{payrollData.reduce((total, entry) => total + +entry.socSec, 0).toFixed(2)}</TableCell>
                              <TableCell sx={{fontWeight:700}}>{payrollData.reduce((total, entry) => total + +entry.medicare, 0).toFixed(2)}</TableCell>
                              <TableCell sx={{fontWeight:700}}>{payrollData.reduce((total, entry) => total + +entry.federalWH, 0).toFixed(2)}</TableCell>
                              <TableCell sx={{fontWeight:700}}>{payrollData.reduce((total, entry) => total + +entry.stateWH, 0).toFixed(2)}</TableCell>
                              <TableCell sx={{fontWeight:700}}>{payrollData.reduce((total, entry) => total + +entry.localWH, 0).toFixed(2)}</TableCell>
                              <TableCell sx={{fontWeight:700}}>{payrollData.reduce((total, entry) => total + +entry.tips, 0).toFixed(2)}</TableCell>
                              <TableCell sx={{fontWeight:700}}>{payrollData.reduce((total, entry) => total + +entry.benefitDeduction, 0).toFixed(2)}</TableCell>
                              <TableCell sx={{fontWeight:700}}>{payrollData.reduce((total, entry) => total + +entry.netPay, 0).toFixed(2)}</TableCell>
                              <TableCell sx={{fontWeight:700}}>{payrollData.reduce((total, entry) => total + +entry.futaTaxable, 0).toFixed(2)}</TableCell>
                              <TableCell sx={{fontWeight:700}}>{payrollData.reduce((total, entry) => total + +entry.futaTax, 0).toFixed(2)}</TableCell>
                              <TableCell sx={{fontWeight:700}}>{payrollData.reduce((total, entry) => total + +entry.futaCredit, 0).toFixed(2)}</TableCell>
                              <TableCell sx={{fontWeight:700}}>{payrollData.reduce((total, entry) => total + +entry.sutaTaxable, 0).toFixed(2)}</TableCell>
                              <TableCell sx={{fontWeight:700}}>{payrollData.reduce((total, entry) => total + +entry.sutaTax, 0).toFixed(2)}</TableCell>
                            </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      color: '#1976D2',
                      mt: 2,
                      fontSize: '1.3rem'
                    }}
                  >
                    {' '}
                    Total Rows: {payrollData.length}
                  </Typography>
                </Box>
                {/* <Grid
                  container
                  sx={{ position: 'absolute', top: '20px', width: '92%' }}
                >
                  <Grid
                    item
                    xs={6}
                    sx={{ display: 'flex', justifyContent: 'flex-start' }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleClose}
                      size="medium"
                      sx={{ fontSize: '1rem', zIndex: 2 }}
                    >
                      Back
                      <ArrowBackIcon sx={{ ml: '4px' }} fontSize="medium" />
                    </Button>
                  </Grid>
                </Grid> */}
              </Grid>
            </Grid>
          </>
        </Box>
      </Modal>
    </>
  );
};

export default ViewPayroll;
